import React, { Component } from "react";

export default class AboutPortain extends Component {
  render() {
    return (
      <>
  {/* About Us*/}
  <div className="about_wwr padding ptb-xs-60"  >
    <div className="container" >
      <div className="row" >
        <div className="col-md-6 col-sm-12" data-aos="fade-up" data-aos-duration="800" data-aos-offset="100">
          <div className="about__content">
            <h2>Why choose us? </h2>
            <p>
            We not only endorse long-term partnerships, but we also practise them. This implies that we will prioritise your career goals as if they were our own. Moreover, securing a job is just the beginning, as we provide benefits that will facilitate your career growth and transformation. 
            </p>
            <p>
            We're more than just a workplace. People are our culture.
            </p>
            <div className="row mt-40">
              <div className="col-md-6">
                <ul>
                  <li>Customer Centric</li>
                  <li>Civic Engagement</li>
                  <li>Career Development</li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul>
                  <li>Establish Bonds</li>
                  <li>Skill Enhancement</li>
                  <li>Build Your Future</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-12" data-aos="fade-down" data-aos-duration="800" data-aos-offset="110">
          <div className="office__block">
            <figure>
              <img alt="WhyUs" src="assets/images/services/whyus_940_640.png" className="img-rounded" style={{boxShadow:'1px 2px 2px 3px #FEECCC'}}/>
            </figure>
            {/* <div className="years__no-wrap">
              <div className="years__no-block">
                <div className="years__no">
                  <span>8<sup>+</sup></span>
                  <p> years of experience in the indstry</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Services Section */}
</>

    );
  }
}
