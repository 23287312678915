import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class HomeCare extends Component {
  render() {
    return (
      <>
        {/* Intro Section */}
        <section
           data-aos="fade-down" data-aos-duration="800"
          className="inner-intro"
          style={{
            background:
              "url(assets/images/services/HomeCare_2000.png)",
            backgroundSize: "cover",
            left: 0,
            top: 0,
            width: "100%",
            height:'600px',
            zIndex: -1,
          }}
        >
          <div className="container"  data-aos="fade-up" data-aos-duration="1000">
            <div className="row title">
              <h1 style={{ color: "#CD1818" }}>HomeCare</h1>
              {/* <div className="page-breadcrumb">
                <span> Industries</span>
              </div> */}
            </div>
          </div>
        </section>
        {/* End Intro Section */}
        {/* Service Section */}
        <div id="services-section" className="padding pt-xs-60">
          <div className="container">
            <div className="row">

              <div className="col-lg-4 col-md-4 col-sm-12 col-sx-12"  data-aos="fade-up" data-aos-duration="800" data-aos-offset="100">
                <div className="single-sidebar-widget">
                  <div className="special-links">
                    <ul>
                      <li>
                        <NavLink to={"/Industries"}>
                          Our Industries
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/HealthCare">HealthCare</NavLink>
                      </li>
                      <li>
                        <NavLink className="active" to="/HomeCare">
                          HomeCare
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/Financial">Financial</NavLink>
                      </li>
                      <li>
                        <NavLink to="/Banking">Banking</NavLink>
                      </li>
                      <li>
                        <NavLink to="/CapitalMarket">Capital Market</NavLink>
                      </li>
                      <li>
                        <NavLink to="/InformationTechnology">
                          Information Technology
                        </NavLink>
                      </li>
                     
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12"  data-aos="fade-down" data-aos-duration="800" data-aos-offset="100">
                <div className="full-pic">
                  <figure>
                    <img
                      src="assets/images/services/HomeCare_500.png"
                      alt="HomeCare Industries"
                    />
                  </figure>
                </div>
                <div className="text-box mt-40">
                  <div className="box-title mb-20">
                    <h2>Home Care</h2>
                  </div>
                  <div className="text-content text-justify">
                    <p>
                      Homecare services are designed to provide caregivers and
                      support staff to individuals who require homecare
                      services.Welcome to Homecare by OneCloud Services. We are
                      proud to oﬀer the highest standard home care services to
                      individuals in need of assistance with daily tasks and
                      activities.
                    </p>
                    <p>
                      Our team of compassionate and skilled caregivers are
                      dedicated to helping our clients maintain their
                      independence and live comfortably in their own homes.Our
                      team of highly trained and compassionate caregivers is
                      ready to help you or your loved ones with a variety of
                      services, including:
                      <hr />
                      <h4 style={{color:'#CD1818',fontSize:18}}  data-aos="fade" data-aos-duration="800" data-aos-offset="110">
                        • 24/7 support <br />• Personal care <br />•
                        Transportation <br />• Companionship <br />• Light
                        housekeeping <br />• Medication management
                      </h4>
                    </p>
                  </div>

                  <div className="row mt-45 clearfix"  data-aos="fade-up" data-aos-duration="800" data-aos-offset="110">
                    <div className="col-md-5">
                      <img
                        className="img-responsive thumbnail"
                        src="assets/images/services/HomeCare_640.png"
                        alt="IT Top Talent"
                      />
                    </div>
                    <div className="col-md-7 text-box">
                      <div className="box-title mb-20 mt-sm-30 mt-xs-30 text-center">
                        <h2 style={{color:'#CD1818',fontSize:18}}>Adult and elderly care at home services: </h2>
                      </div>
                      <div className="text-content text-justify">
                        <p>
                          Adult care services are social, medical, and
                          educational services offered to adults in need of
                          care, support, and assistance. These services can
                          include:
                          
                        </p>
                        <p className="text-left">
                        <br />• Respite care <br />• In-home care <br />• Day
                          programs
                          <br />• Home health aides
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-10 clearfix"  data-aos="fade-up" data-aos-duration="800" data-aos-offset="110">
                    <div className="text-box">
                      <div className="box-title mb-20 mt-sm-30 mt-xs-30">
                        <h2 style={{color:'#CD1818',fontSize:18}}>Homecare for children and young people: </h2>
                      </div>
                      <div className="text-content text-justify">
                        <p>
                          If you are a parent or guardian of a child or young
                          person with complex care needs, you may be looking for
                          a way to give them the best possible care.
                          <br />
                          OneCloud Care at home services for children and young
                          people are an increasingly popular way to provide a
                          safe and comfortable environment for those with
                          special needs. At Care at Home, we understand how
                          important it is to provide a secure and nurturing
                          environment for your child or young person.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-10 clearfix"  data-aos="fade-up" data-aos-duration="800" data-aos-offset="110">
                    <div className="text-box">
                      <div className="box-title mb-20 mt-sm-30 mt-xs-30">
                      <h2 style={{color:'#CD1818',fontSize:18}}>Disability care services: </h2>
                      </div>
                      <div className="text-content text-justify">
                        <p>
                          Do you or a loved one need assistance with daily
                          living due to a disability? Disability Care Services
                          by OneCloud are available to help and provide a
                          variety of services to people with disabilities and
                          their families. <br />
                          We are dedicated to helping individuals who are living
                          with a disability to maintain a quality of life that
                          is as independent and full as possible. Services can
                          include anything from personal care assistance to help
                          with home modifications and job training.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-10 clearfix"  data-aos="fade-up" data-aos-duration="800" data-aos-offset="110">
                    <div className="text-box">
                      <div className="box-title mb-20 mt-sm-30 mt-xs-30">
                      <h2 style={{color:'#CD1818',fontSize:18}}>Well-being and mental health care services: </h2>
                      </div>
                      <div className="text-content text-justify">
                        <p>
                          As the world continues to grapple with the realities
                          of a global pandemic, it has become increasingly
                          important to prioritise your wellbeing and physical
                          and mental health. This is where OneCloud’s well-being
                          care services come in.<br/> OneCloud provides comprehensive
                          support for both physical and mental health. Our
                          well-being care services provide a holistic approach
                          to helping individuals maintain their overall
                          well-being.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Service Section end */}
      </>
    );
  }
}
