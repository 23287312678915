import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import * as user_services from "../utils/user_services";
import withRouter from "../utils/withRouter";

class Register extends Component {
  state = {
    toggle: false,
    selectedOption: "",
    leftLabel: "Employer",
    rightLabel: "Candidate",
    name: "",
    email: "",
    password: "",
    confirm_password: "",
  };

  toggleState = (event) => {
    let toggledValue = event.target.value;
    console.log(toggledValue);
    this.setState({
      toggle: !this.state.toggle,
      selectedOption: toggledValue,
    });
  };

  handleForm = (event) => {
    event.preventDefault();

    let { selectedOption, name, email, password, confirm_password } =
      this.state;

    if (selectedOption === undefined || selectedOption === "") {
      alert("please select your role first...");
      return;
    }
    if (name === undefined || name === "") {
      alert("please enter the name first");
      return;
    }

    if (email === undefined || email === "") {
      alert("Please enter email");
      return;
    }

    if (password === undefined || password === "") {
      alert("Please enter contact person name");
      return;
    }

    if (password.length < 8) {
      alert("Please Enter the password of length greater than 8");
      return;
    }

    if (confirm_password === undefined || confirm_password === "") {
      alert("Please enter confirm password first");
      return;
    }

    if (password !== confirm_password) {
      alert("Password & confirm password mismatch");
      return;
    }

    let payload = {};
    payload.selectedOption = selectedOption;
    payload.name = name;
    payload.email = email;
    payload.password = password;

    console.log(JSON.stringify(payload));

    user_services
      .register(payload)
      .then((res) => {
        console.log(res.data);
        // this.props.navigate("/Login", { state: { name: name } });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    console.log(this.props);
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="main-body">
              <div className="body-inner">
                <form
                  className="form-horizontal"
                  onSubmit={(e) => this.handleForm(e)}
                >
                  <div
                    className="card"
                    style={{ boxShadow: "1px 2px 3px 4px #CCCFFF" }}
                  >
                    <div className="card-content">
                      <h2
                        className="text-center"
                        style={{
                          background: "#0364AD",
                          color: "#FFFEEE",
                          padding: 5,
                          borderRadius: 3,
                        }}
                      >
                        Register an account
                      </h2>

                      <div className="col-sm-12">
                        <div className="form-group">
                          <div className="switch-field">
                            <input
                              type="radio"
                              id="switch_left"
                              name="switchToggle"
                              value={this.state.leftLabel}
                              onChange={this.toggleState}
                            />
                            <label htmlFor="switch_left">
                              {this.state.leftLabel}
                            </label>

                            <input
                              type="radio"
                              id="switch_right"
                              name="switchToggle"
                              value={this.state.rightLabel}
                              onChange={this.toggleState}
                            />
                            <label htmlFor="switch_right">
                              {this.state.rightLabel}
                            </label>
                          </div>
                        </div>

                        <div className="form-group">
                          <label>{this.state.selectedOption} Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            value={this.state.name}
                            onChange={(event) =>
                              this.setState({ name: event.target.value })
                            }
                            required
                            style={{ border: "1px solid #CCCEEE" }}
                          />
                          <span className="input-bar" />
                        </div>

                        <div className="form-group">
                          <label>{this.state.selectedOption} Email</label>
                          <input
                            type="email"
                            required
                            className="form-control"
                            name={this.state.email}
                            value={this.state.email}
                            onChange={(event) =>
                              this.setState({ email: event.target.value })
                            }
                            style={{ border: "1px solid #CCCEEE" }}
                          />
                        </div>

                        <div className="form-group">
                          <label>{this.state.selectedOption} Password</label>
                          <input
                            type="password"
                            required
                            className="form-control"
                            name={this.state.password}
                            value={this.state.password}
                            onChange={(event) =>
                              this.setState({ password: event.target.value })
                            }
                            style={{ border: "1px solid #CCCEEE" }}
                          />
                        </div>

                        <div className="form-group">
                          <label>
                            {this.state.selectedOption} Confirm Password
                          </label>
                          <input
                            type="password"
                            required
                            className="form-control"
                            name={this.state.confirm_password}
                            value={this.state.confirm_password}
                            onChange={(event) =>
                              this.setState({
                                confirm_password: event.target.value,
                              })
                            }
                            style={{ border: "1px solid #CCCEEE" }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="card-action no-border text-right">
                      <NavLink
                        to="/Login"
                        className="btn btn-sm pull-left"
                        style={{
                          background: "#d81a1a",
                          color: "#FFF",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        Already a Member ? Please Login
                      </NavLink>

                      <button
                        className="btn btn-sm"
                        style={{
                          background: "#0F64DB",
                          color: "#FFF",
                          fontWeight: "500",
                        }}
                      >
                        Sign Up
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Register);
