import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class Overseas extends Component {
  render() {
    return (
      <>
        {/* Intro Section */}
        <section
          className="inner-intro"
          data-aos="fade"
          data-aos-duration="800"
          style={{
            background: "url(assets/images/services/Overseas_2000.png)",
            backgroundSize: "cover",
            left: 0,
            top: 0,
            width: "100%",
            height: "600px",
            zIndex: -1,
          }}
        >
          <div className="container" data-aos="fade-up" data-aos-duration="900">
            <div className="row title">
              <h1 data-title="Leadership">
                <span style={{ color: "#CD1818" }}>Overseas Recruitment</span>
              </h1>
            </div>
          </div>
        </section>
        {/* Intro Section */}
        <section className="padding ptb-xs-60">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-xs-40 pb-xs-40"
                data-aos="fade-left"
                data-aos-duration="1000"
                data-aos-offset="100"
              >
                <div className="tab-title">
                  <ul className="clearfix">
                    <li>
                      <NavLink to={"/Service"}>Our Services</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Contracting"}>
                        Contracting Services
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Permanent"}>Permanent Services</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Temporary"}>
                        Temporary Agency Staffing
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/HomeCare"}>HomeCare Services</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Overseas"} className="active">
                        Overseas Recruitment
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Training"}>Traning</NavLink>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className="col-lg-8 col-md-8 col-sm-12 col-xs-12"
                data-aos="fade-up"
                data-aos-duration="900"
                data-aos-offset="100"
              >
                <div className="tab-content">
                  <div className="row">
                    <div className="col-sm-12" style={{ top: -15 }}>
                      <img
                        src="assets/images/services/overseas_500.png"
                        alt="Permanent Recruitment Services"
                      />
                    </div>
                    <div className="col-sm-12">
                      <h2 className="pt-30">Overseas Recruitment Service</h2>
                      <p className="text-justify text-content">
                        Overseas recruitment has become increasingly popular for
                        professionals seeking new career opportunities in
                        different parts of the world. One region that has gained
                        attention from job seekers is the United Arab Emirates
                        (UAE) and the wider Middle East. If you're interested in
                        working in this part of the world, it's important to
                        understand the intricacies of the recruitment process
                        and how to navigate it effectively.
                      </p>
                      <p className="text-justify">
                        At our agency, we specialize in providing recruitment
                        services for teachers, healthcare professionals -
                        specifically nurses, care Assistant and doctors - who
                        are looking to work in the UAE and the Middle East. Our
                        team has extensive experience in this area, and we work
                        closely with our clients to ensure that they find the
                        right job that meets their professional and personal
                        goals.
                      </p>
                      <p
                        className="text-justify"
                        data-aos="fade-down"
                        data-aos-duration="900"
                        data-aos-offset="100"
                      >
                        When it comes to overseas recruitment, there are a few
                        key factors to consider. First, it's important to
                        understand the visa requirements for the country you're
                        interested in working in. for example, has strict visa
                        requirements that can vary depending on your profession
                        and the type of job you're applying for. Our team can
                        help guide you through the visa application process and
                        ensure that you have all the necessary documents and
                        information.
                        <br />
                        <br /> Second, it's important to have a strong
                        understanding of the job market in the UAE and the wider
                        Middle East. Our agency has strong relationships with
                        employers in the region, which means that we're able to
                        provide our clients with access to exclusive job
                        opportunities that might not be advertised elsewhere.
                        <br />
                        <br />
                        Finally, it's important to have a clear understanding of
                        the culture and work environment in the UAE and the
                        Middle East. Our team can provide you with insights and
                        advice on what to expect in terms of working hours, work
                        culture, and other factors that might impact your
                        experience working overseas.
                        <br /> Overall, overseas recruitment can be a great way
                        to advance your career and gain valuable international
                        experience. If you're interested in working in the UAE
                        or the wider Middle East, our agency can provide you
                        with the specialist services and support you need to
                        make your dream job a reality.
                      </p>
                      <p
                        className="text-justify"
                        data-aos="fade"
                        data-aos-duration="900"
                        data-aos-offset="110"
                      >
                        We are also specialize in providing recruitment services
                        for the healthcare sector here in UK. We have extensive
                        experience in hiring candidates from outside the UK from
                        the countries like Nigeria, Ethiopia, Middle East, South
                        Asia, etc and we are committed to finding the best
                        talent for our clients. We work closely with healthcare
                        organizations, regulatory bodies, and professional
                        associations in these regions to identify the best
                        candidates for our clients. We have a thorough
                        understanding of the immigration laws and regulations in
                        the UK. We are able to provide expert advice and
                        guidance to candidates on the visa application process,
                        which can be complex and confusing.
                        <br /> <br />
                        OneCloud also ensures that candidates are fully vetted
                        and meet all the necessary qualifications and
                        requirements for the healthcare sector in the UK. We
                        conduct thorough background checks and reference checks
                        to ensure that candidates are reliable, trustworthy, and
                        have the necessary skills and experience.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
