import React, { Component } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { history } from "./history";

import MainPage from "./home/MainPage";

import ProtectedRoute from "./utils/ProtectedRoute";

import Login from "./home/Login";
import Register from "./home/Register";

import Home from "./home/Home";
import AboutUs from "./home/AboutUs";

import Service from "./home/Service";
import Permanent from "./home/Permanent";
import Overseas from "./home/Overseas";
import Temporary from "./home/Temporary";
import Contracting from "./home/Contracting";
import HomeCare from "./home/HomeCare";
import Training from "./home/Training";

import Industries from "./home/Industries";
import CapitalMarket from "./home/CapitalMarket";
import InformationTechnology from "./home/InformationTechnology";
import Banking from "./home/Banking";
import Financial from "./home/Financial";
import HealthCare from "./home/HealthCare";

import Contact from "./home/Contact";
import JobSeeker from "./home/JobSeeker";
import AdvanceSearch from "./home/AdvanceSearch";

// topmenu
import UploadCv from "./home/UploadCv";
import ForgotPassword from "./home/ForgotPassword";
import CostCalculator from "./home/CostCalculator";
import PageError from "./home/PageError";

import JobList from './home/JobList'
import JobDetails from "./home/JobDetails";

//backend


class App extends Component {
  render() {
    return (
      <Router history={history}>
        <Routes>

          <Route path="/" element={<MainPage />}>
            <Route index element={<Home />} />
            <Route path="/AboutUs" element={<AboutUs />} />

            <Route path="/CostCalculator" element={<CostCalculator />} />

            <Route path="/Service" element={<Service />} />
            <Route path="/Permanent" element={<Permanent />} />
            <Route path="/Overseas" element={<Overseas />} />
            <Route path="/Temporary" element={<Temporary />} />
            <Route path="/Contracting" element={<Contracting />} />
            <Route path="/HomeCare" element={<HomeCare />} />
            <Route path="/Training" element={<Training />} />

            <Route path="/Industries" element={<Industries />} />
            <Route path="/CapitalMarket" element={<CapitalMarket />} />
            <Route
              path="/InformationTechnology"
              element={<InformationTechnology />}
            />
            <Route path="/Banking" element={<Banking />} />
            <Route path="/Financial" element={<Financial />} />
            <Route path="/HealthCare" element={<HealthCare />} />

            <Route path="/Contact" element={<Contact />} />
            <Route path="/JobSeeker" element={<JobSeeker />} />
            <Route path="/AdvanceSearch" element={<AdvanceSearch />} />
            <Route path="/UploadCv" element={<UploadCv />} />
            <Route path="/Register" element={<Register />} />

            <Route path="/Login" element={<Login />} />
            <Route path="/ForgotPassword" element={<ForgotPassword />} />

            <Route path="/JobList" element={<JobList />} />
            <Route path="/JobDetails/:JobId" element={<JobDetails />} />

            <Route path="*" element={<PageError />} />
          </Route>
          
        </Routes>
      </Router>
    );
  }
}

export default App;
