import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class Service extends Component {
  render() {
    return (
      <>
        <section
          data-aos="fade-up"
          data-aos-duration="1000"
          className="inner-intro padding"
          style={{
            background:
              "url(assets/images/services/Service_2000.png)",
            backgroundSize: "cover",
            left: 0,
            top: 0,
            width: "100%",
            height: "550px",
            zIndex: -1,
          }}
        ></section>
        {/* Services Section */}
        <section
          id="services"
          className="our_service padding ptb-xs-60"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-offset="300"
        >
          <div className="container">
            <div className="row pb-30 text-center">
              <div className="col-sm-12 mb-20">
                <h2>
                  <span>Our</span> Services
                </h2>
                <span className="b-line" />
              </div>
            </div>

            <div className="row">

            
              <div className="col-sm-4" data-aos="fade" data-aos-duration="800" data-aos-offset="210">
                <div className="service_box hi-icon-effect-3 hi-icon-effect-3b">
                  <div className="service_icon ">
                    <i className="hi-icon fa fa-thumbs-up" aria-hidden="true" />
                  </div>
                  <div className="service_details">
                    <h5 className="font-color_base no_stripe">
                      <NavLink to={"/Contracting"}>
                        Contracting Services
                        <br />
                      </NavLink>
                    </h5>
                  </div>
                  <div className="service_content">
                    <p>
                      We understand that you need to balance accessing niche or
                      specific skill sets with the ability to scale your
                      workforce as needed.
                    </p>
                  </div>
                </div>
              </div>
              

              <div className="col-sm-4" data-aos="fade" data-aos-duration="800" data-aos-offset="210">
                <div className="service_box hi-icon-effect-3 hi-icon-effect-3b">
                  <div className="service_icon">
                    <i className="hi-icon fa fa-cloud" aria-hidden="true" />
                  </div>
                  <div className="service_details">
                    <h5 className="font-color_base no_stripe">
                      <NavLink to={"/Permanent"}>
                        Permanent Recruitment
                        <br />
                      </NavLink>
                    </h5>
                  </div>
                  <div className="service_content">
                    <p>
                      Welcome to our Permanent Recruitment services, where we
                      understand that finding the right candidate who can fulfil..
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-4" data-aos="fade" data-aos-duration="800" data-aos-offset="210">
                <div className="service_box hi-icon-effect-3 hi-icon-effect-3b">
                  <div className="service_icon">
                    <i className="hi-icon fa fa-empire" aria-hidden="true" />
                  </div>
                  <div className="service_details">
                    <h5 className="font-color_base no_stripe">
                      <NavLink to={"/Temporary"}>
                        Temporary Recruitment
                        <br />
                      </NavLink>
                    </h5>
                  </div>
                  <div className="service_content">
                    <p>
                      We are committed to working with you as your lifelong
                      partner to find temporary candidates who provide the niche
                      expertise
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-4" data-aos="fade" data-aos-duration="800" data-aos-offset="210">
                <div className="service_box hi-icon-effect-3 hi-icon-effect-3b">
                  <div className="service_icon">
                    <i className="hi-icon fa fa-home" aria-hidden="true" />
                  </div>
                  <div className="service_details">
                    <h5 className="font-color_base no_stripe">
                      <NavLink to={"/HomeCare"}>
                        HomeCare Services
                        <br />
                      </NavLink>
                    </h5>
                  </div>
                  <div className="service_content">
                    <p>
                      . We are proud to oﬀer the highest standard home care
                      services to individuals in need of assistance with daily
                      tasks and activities.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-4" data-aos="fade" data-aos-duration="800" data-aos-offset="210">
                <div className="service_box hi-icon-effect-3 hi-icon-effect-3b">
                  <div className="service_icon">
                    <i className="hi-icon fa fa-plane" aria-hidden="true" />
                  </div>
                  <div className="service_details">
                    <h5 className="font-color_base no_stripe">
                      <NavLink to={"/Overseas"}>
                        Overseas Recruitment
                        <br />
                      </NavLink>
                    </h5>
                  </div>
                  <div className="service_content">
                    <p>
                      Overseas recruitment has become increasingly popular for
                      professionals seeking new career opportunities in..
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-4" data-aos="fade" data-aos-duration="800" data-aos-offset="210">
                <div className="service_box hi-icon-effect-3 hi-icon-effect-3b">
                  <div className="service_icon">
                    <i
                      className="hi-icon fa fa-paper-plane"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="service_details">
                    <h5 className="font-color_base no_stripe">
                      <NavLink to={"/Training"}>
                        Training
                      </NavLink>
                    </h5>
                  </div>
                  <div className="service_content">
                    <p>
                      OneCloud is dedicated to assisting you in finding the
                      perfect opportunity, at the ideal time. Our expert and
                      skilled recruitment
                    </p>
                    <NavLink to="/">
                      <button className="button-64 pull-right">
                        <span className="text">
                          Coming Soon <i className="fa fa-arrow-right"></i>{" "}
                        </span>
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
      </>
    );
  }
}
