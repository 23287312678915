import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class Permanent extends Component {
  render() {
    return (
      <>
        {/* Intro Section */}
        <section
          className="inner-intro"
          data-aos="fade"
          data-aos-duration="800"
          style={{
            background: "url(assets/images/services/Permanent_2000.png)",
            backgroundSize: "cover",
            left: 0,
            top: 0,
            width: "100%",
            height: "600px",
            zIndex: -1,
          }}
        >
          <div
            className="container"
            data-aos="fade-down"
            data-aos-duration="800"
          >
            <div className="row title">
              <h1 data-title="Leadership">
                <span style={{ color: "#CD1818", fontWeight: "bold" }}>
                  Permanent
                </span>
              </h1>
            </div>
          </div>
        </section>
        {/* Intro Section */}
        <section className="padding ptb-xs-60">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-xs-40 pb-xs-40"
                data-aos="fade-up"
                data-aos-duration="900"
                data-aos-offset="100"
              >
                <div className="tab-title">
                  <ul className="clearfix">
                    <li>
                      <NavLink to={"/Service"}>Our Services</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Contracting"} >
                        Contracting Services
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Permanent"} className="active">Permanent Services</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Temporary"}>
                        Temporary Agency Staffing
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/HomeCare"}>HomeCare Services</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Overseas"}>Overseas Recruitment</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Training"}>Traning</NavLink>
                    </li>
                  </ul>
                </div>
                {/* <div className="company-brochure mt-30">
                  <h4>Downlaod Brochure</h4>
                  <a href="#">
                    <img src="assets/images/flyer.jpg" alt="" />
                  </a>
                </div> */}
              </div>

              <div
                className="col-lg-8 col-md-8 col-sm-12 col-xs-12"
                data-aos="fade-down"
                data-aos-duration="800"
                data-aos-offset="100"
              >
                <div className="tab-content">
                  <div className="row">
                    <div className="col-sm-12" style={{ top: -15 }}>
                      <img
                        src="assets/images/services/Permanent_500.png"
                        alt="Permanent Recruitment Services"
                      />
                    </div>
                    <div className="col-sm-12">
                      <h2 className="pt-30">Permanent Recruitment Service</h2>
                      <p className="text-justify">
                        Welcome to our Permanent Recruitment services, where we
                        understand that finding the right candidate who can
                        fulfil the requirements of a specific role and bring
                        added value to your organisation is crucial. Our pledge
                        is to be your enduring ally in the realm of employment,
                        linking you with individuals who can aid your company in
                        prospering.
                      </p>
                      <p className="text-justify">
                        Through our expertise in workforce management and access
                        to extensive talent networks, we can connect you with
                        the necessary skills and expertise to succeed. Our suite
                        of permanent recruitment services aims to help you
                        achieve your strategic objectives by providing the right
                        people for the job.
                      </p>
                      <p className="text-justify">
                        We acknowledge the distinctiveness of each organisation,
                        that’s why we invest the necessary time to comprehend
                        the particular challenges and prospects of your
                        business. Our goal is to locate enthusiastic individuals
                        who can help revolutionize your company by aligning
                        people and purpose, expanding your access to knowledge,
                        and delivering outstanding customer service. Our
                        services cover everything from recruiting a single
                        skilled specialist to building a whole team project, and
                        we're here to support you throughout every stage of the
                        process. We will work together to tackle everything from
                        candidate sourcing, application screening, assessment,
                        and long-term management and growth of your workforce,
                        creating a customized solution for your permanent
                        recruitment needs.
                      </p>
                      <p className="text-justify">
                        By utilizing advanced technology, tested methods, and
                        extensive knowledge, we guarantee that we can provide
                        you with the necessary personnel to accomplish your
                        strategic objectives. So, if you're looking for
                        continual and committed assistance in finding the right
                        candidates, look no further than our Permanent
                        Recruitment services. Building Successful Partnerships,
                        One Hire at a Time.
                      </p>
                    </div>
                  </div>
                </div>

                {/*                 
                <div className="pt-60 pt-xs-60 ">
                  <div className="row mb-60 bottom__brdr pb-40">
                    <div className="col-sm-3 no-padding t_cell text-center">
                      <img src="assets/images/quote-author.jpg" alt="" />
                    </div>
                    <div className="col-sm-9 no-padding t_cell">
                      <div className="partner__content">
                        <h3>Alex Blackwell</h3>
                        <span className="career_sub-title">
                          Managing Director
                        </span>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisicing
                          elit eiusmod tempor incididunt ut labore et dolore
                          magna aliqua. Enim minim veniam quis nostrud Lorem
                          ipsum dolor sit amet consectetur adipisicing elit
                          eiusmod tempor incididunt ut labore et dolore magna
                          aliqua. Enim minim veniam quis nostrud{" "}
                        </p>
                        <a href="#" className="abt__link">
                          {" "}
                          About Me{" "}
                          <i
                            className="fa fa-chevron-circle-right abt__link-arrow"
                            aria-hidden="true"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
