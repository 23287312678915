import React, { Component } from "react";
import { FaHeadset,FaSearchengin,FaChartBar,FaPaperPlane } from "react-icons/fa";

export default class OurProcess extends Component {
  render() {
    return (
      <>
        {/*Our Progress Start*/}
        <section className="progress_wrap__block  pb-50">
          <div className="container" data-aos="fade-up" data-aos-duration="800" data-aos-offset="100">
            <div className="row">
              <div className="col-md-12">
                <div className="section_title__block text-center mb-30">
                  <h2>Our Process</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3 col-sm-6">
                <div className="text-center mt-30">
                  <span><FaHeadset size={48} color="#0f64db"/></span>
                  <h5>LISTEN</h5>
                  <p>
                    Our initial step involves meeting with you to gain a deep
                    understanding of your objectives, the specific positions you
                    wish to fill, and the distinctive requirements of your open
                    roles.
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="text-center mt-30">
                  <span><FaSearchengin size={48} color="#0f64db"/></span>
                  <h5>SEARCH,INTERVIEW,PREPARE</h5>
                  <p>
                    Utilizing our available resources, we will actively search
                    for qualified candidates and conduct interviews to create a
                    shortlist of potential candidates for a final interview with
                    you.
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="text-center mt-30">
                  <span><FaChartBar size={48} color="#0f64db"/></span>
                  <h5>ASSESS & EVALUATE</h5>
                  <p>
                    Following the conclusive interview stage, we will undertake
                    a thorough and comprehensive assessment and evaluation
                    process. This involves conducting reference checks, skills
                    assessments, background checks, and other relevant
                    evaluations to ensure that the candidates are well-suited
                    for the position.
                  </p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                {/* <div className="single_progress__block mt-30"> */}
                <div className="text-center mt-30"> 
                  <span><FaPaperPlane size={48} color="#0f64db"/></span>
                  <h5>OFFER, HIRE & ONBOARD</h5>
                  <p>
                    Based on your preferences, we will either support or lead
                    the process of extending an offer to the selected candidate,
                    as well as provide assistance with their onboarding
                    procedures.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Our Progress End*/}
      </>
    );
  }
}
