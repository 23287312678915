import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class EmployerCandidateSection extends Component {
  render() {
    return (
      <>
        {/*Our Progress Start*/}
        <section  style={{ background: "url(assets/images/hero-bg.jpg)",backgroundSize:'cover' }}>
          <div  data-aos="fade-down" data-aos-duration="800" className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 clearfix">
                  <div style={{borderBottom:'1px solid #cccFFF'}}>
                    <div
                      className="padding"
                      style={{ color: "#FFF" }}
                    >
                      <span style={{ color: "#FFF", fontSize: 30 }}>
                        I'm an
                      </span>
                      <h2
                        style={{ color: "#FFF", marginTop: 15, fontSize: 44 }}
                      >
                        EMPLOYER
                      </h2>
                      <p style={{ marginTop: 20 }}>
                        Signin companies are able to post new job offers,
                        <br /> searching for candidates..
                      </p>
                      <NavLink
                        to="/Login"
                        className="btn btn-md mt-15"
                        style={{ background: "#0f64db", color: "#FFF" }}
                      >
                        <i className="fa fa-user"></i> REGISTER AS COMPANY
                      </NavLink>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 clearfix">
                  <div>
                    <div className="padding" style={{ color: "#FFF" }}>
                      <span style={{ color: "#FFF", fontSize: 30 }}>I'm a</span>
                      <h2
                        style={{ color: "#FFF", marginTop: 15, fontSize: 44 }}
                      >
                        CANDIATE
                      </h2>
                      <p style={{ marginTop: 20 }}>
                        Signin companies are able to post new job offers,
                        <br /> searching for candidates..
                      </p>
                      <NavLink
                        to="/Login"
                        className="btn btn-md mt-15"
                        style={{ background: "#0f64db", color: "#FFF" }}
                      >
                        <i className="fa fa-user"></i> REGISTER AS CANDIDATE
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*Our Progress End*/}
      </>
    );
  }
}

export default EmployerCandidateSection;
