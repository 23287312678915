import React, { Component } from "react";
import Banner from "./Banner";
import ShortLinks from "./ShortLinks";
import CandidateSection from './CandidateSection';
import OurCompany from "./OurCompany";
import AboutPortain from "./AboutPortion";
import ServiceSection from "./ServiceSection";
import IndustriesSection from "./IndustriesSection";
import BlogSection from "./BlogSection";
// import JobSection from "./JobSection";
import OurProcess from "./OurProcess";
import EmployerCandidateSection from './EmployerCandidateSection';
// import Slider from "./Slider";


export default class Home extends Component {
  render() {
   
    return (
      <>
        <Banner />
        <ShortLinks />
        <CandidateSection />
        <OurCompany />
        <OurProcess />
        <EmployerCandidateSection />
        {/* <JobSection /> */}
        <BlogSection />
        <ServiceSection />
        <AboutPortain />
        <IndustriesSection />
       
        
      </>
    );
  }
}
