import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class InformationTechnology extends Component {
  render() {
    return (
      <>
        {/* Intro Section */}
        <section
         data-aos="fade-up" data-aos-duration="800"
          className="inner-intro padding"
          style={{
            background: "url(assets/images/services/IT_2000.png)",
            backgroundSize: "cover",
            left: 0,
            top: 0,
            width: "100%",
            height:"600px",
            zIndex: -1,
          }}
        >
          <div className="container"  data-aos="fade-up" data-aos-duration="800">
            <div className="row title">
              <h1 style={{ color: "#CD1818" }}>Information technology</h1>
              {/* <div className="page-breadcrumb">
                <span style={{ color: "#CD1818" }}>Industries</span>
              </div> */}
            </div>
          </div>
        </section>
        {/* End Intro Section */}

        {/* Service Section */}
        <div id="services-section" className="padding pt-xs-60">
          <div className="container">
            <div className="row">

              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12"  data-aos="fade-down" data-aos-duration="800" data-aos-offset="100">
                <div className="single-sidebar-widget">
                  <div className="special-links">
                    <ul>
                      <li>
                        <NavLink to={"/Industries"}>
                          Our Industries
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/HealthCare">HealthCare</NavLink>
                      </li>
                      <li>
                        <NavLink to="/HomeCare">HomeCare</NavLink>
                      </li>
                      <li>
                        <NavLink to="/Financial">Financial</NavLink>
                      </li>
                      <li>
                        <NavLink to="/Banking">Banking</NavLink>
                      </li>
                      <li>
                        <NavLink to="/CapitalMarket">Capital Market</NavLink>
                      </li>
                      <li>
                        <NavLink className="active" to="/InformationTechnology">
                          Information Technology
                        </NavLink>
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12"  data-aos="fade" data-aos-duration="800" data-aos-offset="100">
                <div className="full-pic">
                  <figure>
                    <img
                      height={400}
                      width={"100%"}
                      src="assets/images/services/IT_500.png"
                      alt="Information Technology Industries"
                    />
                  </figure>
                </div>
                <div className="text-box mt-40">
                  <div className="box-title mb-20">
                    <h2>Information Technology</h2>
                  </div>
                  <div className="text-content text-justify">
                    <p>
                      The technology industry is rapidly growing and has become
                      a critical part of the global economy. From software
                      development to hardware manufacturing, technology
                      companies are constantly innovating and shaping the world
                      we live in. However, finding the right talent to drive
                      these industries forward can be a challenging task. This
                      is where a OneCloud recruitment agency can help.
                    </p>
                    <p>
                      OneCloud is a leading recruitment agency that specializes
                      in placing top-tier technology professionals in various
                      industries such as IT, engineering, software development,
                      and more. With a focus on providing tailored recruitment
                      solutions, OneCloud has become a trusted partner to
                      numerous technology companies looking to find the right
                      talent to grow their businesses
                    </p>
                  </div>

                  <div className="row mt-45 clearfix"  data-aos="fade" data-aos-duration="800" data-aos-offset="110">
                    <div className="col-md-5">
                      <img
                        className="img-responsive thumbnail"
                        src="assets/images/services/IT_640.png"
                        alt="IT Top Talent"
                      />
                    </div>
                    <div className="col-md-7 text-box">
                      <div className="box-title mb-20 mt-sm-30 mt-xs-30">
                        <h3>Identifying Top Talent</h3>
                      </div>
                      <div className="text-content text-justify">
                        <p>
                          One of the primary ways that OneCloud contributes to
                          the technology industry is by identifying and
                          recruiting top talent. OneCloud's recruitment process
                          involves rigorous screening and vetting to ensure that
                          only the most qualified candidates are selected. By
                          working with OneCloud, technology companies can access
                          a pool of talented professionals who are ready to take
                          on new challenges and help drive their business
                          forward.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-10 clearfix"  data-aos="fade-down" data-aos-duration="800" data-aos-offset="110">
                    <div className="text-box">
                      <div className="box-title mb-20 mt-sm-30 mt-xs-30">
                        <h3>Staying Up-to-Date with Industry Trends </h3>
                      </div>
                      <div className="text-content text-justify">
                        <p>
                          The technology industry is constantly evolving, and
                          staying up-to-date with the latest trends and
                          developments is essential. OneCloud's recruitment team
                          is always up-to-date with the latest industry trends,
                          allowing them to understand the specific needs of each
                          company and find candidates with the right skills and
                          experience. By keeping abreast of the latest industry
                          trends, OneCloud is better equipped to match the right
                          talent with the right companies.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-10 clearfix"  data-aos="fade" data-aos-duration="800" data-aos-offset="110">
                    <div className="text-box">
                      <div className="box-title mb-20 mt-sm-30 mt-xs-30">
                        <h3>Providing Tailored Recruitment Solutions </h3>
                      </div>
                      <div className="text-content text-justify">
                        <p>
                          OneCloud understands that each technology company has
                          unique recruitment needs. That's why they offer
                          customized recruitment solutions to suit the specific
                          requirements of each client. Whether a company needs
                          to fill a single position or recruit an entire team,
                          OneCloud can provide tailored recruitment solutions to
                          meet their needs. This personalized approach ensures
                          that each client receives the support they need to
                          find the right talent for their business.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-10 clearfix"  data-aos="fade-up" data-aos-duration="800" data-aos-offset="110">
                    <div className="text-box">
                      <div className="box-title mb-20 mt-sm-30 mt-xs-30">
                        <h3>Supporting Diversity and Inclusion </h3>
                      </div>
                      <div className="text-content text-justify">
                        <p>
                          OneCloud is committed to promoting diversity and
                          inclusion in the technology industry. They understand
                          that a diverse workforce is essential for driving
                          innovation and creating a dynamic workplace. That's
                          why they actively seek out candidates from diverse
                          backgrounds and work to eliminate bias in the
                          recruitment process. By promoting diversity and
                          inclusion, OneCloud is helping to create a more
                          inclusive and equitable technology industry.
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Service Section end */}
      </>
    );
  }
}
