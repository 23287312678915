import React, { Component } from "react";
// import { NavLink } from "react-router-dom";

import CostCalculatorForm from "./CostCalculatorForm";

export class CostCalculator extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     fullname: "",
  //     phonenumber: "",
  //     email: "",
  //     address: "",
  //     message: "",
  //     role: "",
  //     salary: "",
  //     leftLabel: "Percentage",
  //     rightLabel: "Flat",
  //     selectedLabel: "",
  //     percent: "8",
  //     flat: "",
  //   };
  // }

 

  

  render() {
    return (
      <>
       <CostCalculatorForm />
      </>
    );
  }
}

export default CostCalculator;
