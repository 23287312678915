import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class Banking extends Component {
  render() {
    return (
      <>
        {/* Intro Section */}
        <section
         data-aos="fade-down" data-aos-duration="800"
          className="inner-intro  padding"
          style={{
            background:
              "url(assets/images/services/Banking_2000.png)",
            backgroundSize: "cover",
            left: 0,
            top: 0,
            width: "100%",
            height:'600px',
            zIndex: -1,
          }}
        >
          <div className="container"  data-aos="fade-up" data-aos-duration="800">
            <div className="row title">
              <h1 style={{ color: "#CD1818" }}>Banking</h1>
              {/* <div className="page-breadcrumb">
                <span >Industries</span>
              </div> */}
            </div>
          </div>
        </section>
        {/* End Intro Section */}
        {/* Service Section */}
        <div id="services-section" className="padding pt-xs-60">
          <div className="container">
            <div className="row">

              <div className="col-lg-4 col-md-4 col-sm-12 col-sx-12"  data-aos="fade-down" data-aos-duration="800" data-aos-offset="100">
                <div className="single-sidebar-widget">
                  <div className="special-links">
                    <ul>
                      <li>
                        <NavLink to={"/Industries"}>
                          Our Industries
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/HealthCare">HealthCare</NavLink>
                      </li>
                      <li>
                        <NavLink to="/HomeCare">HomeCare</NavLink>
                      </li>
                      <li>
                        <NavLink to="/Financial">Financial</NavLink>
                      </li>
                      <li>
                        <NavLink className="active" to="/Banking">
                          Banking
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/CapitalMarket">Capital Market</NavLink>
                      </li>
                      <li>
                        <NavLink to="/InformationTechnology">
                          Information Technology
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12"  data-aos="fade" data-aos-duration="800" data-aos-offset="100">
                <div className="full-pic">
                  <figure>
                    <img
                      src="assets/images/services/Banking_500.png"
                      alt="Financial Industries"
                    />
                  </figure>
                </div>
                <div className="text-box mt-40">
                  <div className="box-title mb-20">
                    <h2>Banking Industries</h2>
                  </div>
                  <div className="text-content text-justify">
                    <p>
                      The banking industry is a broad and complex sector that
                      encompasses a range of financial services and
                      institutions, including commercial banks, investment
                      banks, credit unions, and other financial intermediaries.
                      The industry plays a critical role in facilitating
                      economic growth by providing individuals, businesses, and
                      governments with access to financial services, such as
                      savings accounts, loans, credit cards, and investment
                      products.
                    </p>
                    <strong>
                      Within the banking industry, there are several key areas
                      of focus, including:
                    </strong>
                    <ul style={{ listStyleType: "none" }}>
                      <li>
                        <strong>1. Retail Banking</strong>: This involves
                        providing financial services to individuals and small
                        businesses. These services can include checking and
                        savings accounts, personal loans, credit cards, and
                        other consumer-focused products.
                      </li>
                      <li>
                        <strong>2. Commercial Banking</strong>: This involves
                        providing financial services to larger businesses and
                        corporations. These services can include lending, cash
                        management, and other specialized services tailored to
                        the needs of corporate clients.
                      </li>
                      <li>
                        <strong>3. Investment Banking</strong>: This involves
                        providing financial advice and services to clients
                        looking to raise capital or invest in securities.
                        Investment banks typically work with large corporations,
                        governments, and institutional investors.
                      </li>
                      <li>
                        <strong>4. Wealth Management</strong>: This involves
                        providing financial planning and investment advice to
                        high-net-worth individuals and families.
                      </li>
                      <li>
                        <strong>5. Insurance</strong>: Some banks also offer
                        insurance products, such as life insurance, health
                        insurance, and property and casualty insurance.
                      </li>
                    </ul>
                    <p>
                      The banking industry is highly competitive and constantly
                      evolving, with new technologies, regulatory changes, and
                      customer demands driving innovation and growth. As a
                      result, banks and other financial institutions need to be
                      able to attract and retain top talent in order to stay
                      ahead of the curve.
                    </p>
                    <p>
                      This is where OneCloud Services can play an important
                      role. OneCloud Services is a specialized recruitment
                      agency that focuses on the banking and financial services
                      industry. They have a deep understanding of the sector and
                      the skills, qualifications, and experience required to
                      succeed in this field.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 faq-service mb-30 mt-30"  data-aos="fade" data-aos-duration="800" data-aos-offset="100">
                <h3>
                  OneCloud Services can help banks and financial institutions
                  to:
                </h3>

                <div className="panel-group service-faq faq" id="accordion">
                 
                  <div className="panel panel-default panel-primary">
                    <div className="panel-heading">
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapse-1"
                          aria-expanded="true"
                        >
                          1. Access a large talent pool:
                          <i className="fa fa-plus collape-plus" />
                        </a>
                      </h4>
                    </div>
                    <div id="collapse-1" className="panel-collapse collapse in">
                      <div className="panel-body">
                        <p>
                          OneCloud Services has an extensive network of
                          candidates with diverse skills and experience levels
                          in the banking industry. This means they can find the
                          right candidate for any role, whether it be entry-
                          level or executive-level positions.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default panel-primary">
                    <div className="panel-heading">
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapse-2"
                          aria-expanded="false"
                        >
                          2. Save time and resources:
                          <i className="fa fa-plus collape-plus" />
                        </a>
                      </h4>
                    </div>
                    <div id="collapse-2" className="panel-collapse collapse">
                      <div className="panel-body">
                        <p>
                          OneCloud Services can save banks and financial
                          institutions time and resources by handling all
                          aspects of the recruitment process, including
                          screening, interviewing, and shortlisting candidates.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default panel-primary">
                    <div className="panel-heading">
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapse-3"
                          aria-expanded="false"
                        >
                          3. Provide market insights:
                          <i className="fa fa-plus collape-plus" />
                        </a>
                      </h4>
                    </div>
                    <div id="collapse-3" className="panel-collapse collapse">
                      <div className="panel-body">
                        <p>
                          OneCloud Services has a deep understanding of the
                          banking industry and can provide valuable insights on
                          industry trends, talent shortages, and other factors
                          that may impact hiring strategies.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default panel-primary">
                    <div className="panel-heading">
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapse-4"
                          aria-expanded="false"
                        >
                          4. Deliver customized solutions:
                          <i className="fa fa-plus collape-plus" />
                        </a>
                      </h4>
                    </div>
                    <div id="collapse-4" className="panel-collapse collapse">
                      <div className="panel-body">
                        <p>
                          OneCloud Services can provide customized recruitment
                          solutions that are tailored to the specific needs of
                          banks and financial institutions. This can include
                          temporary staffing, contract staffing, and permanent
                          placement services.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 faq-service mb-30 mt-20">
                <p>
                  Overall, OneCloud Services can play a critical role in helping
                  banks and financial institutions to attract and retain top
                  talent in a highly competitive and constantly evolving
                  industry. By leveraging their expertise and network of
                  candidates, OneCloud Services can help to build strong and
                  effective teams that can drive growth and success..
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Service Section end */}
      </>
    );
  }
}
