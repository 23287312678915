import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Image1 from "../assets/SliderImage/12.png";
import Image2 from "../assets/SliderImage/2.png";
import Image3 from "../assets/SliderImage/3.png";

export default class Banner extends Component {
  state = {
    index: 0,
  };

  componentDidMount() {}

  handleSelect = (selectedIndex, e) => {
    this.setState({ index: selectedIndex });
  };

  render() {
    return (
      <>
        <div className="main-banner">
          <Carousel
            showStatus={false}
            renderIndicator={false}
            infiniteLoop={true}
            autoPlay={true}
            showThumbs={false}
            selectedItem={this.state.index}
            onSelect={this.handleSelect}
            interval={5000}
            transitionTime={1000}
          >
            <div>
              <img src={Image1} />
            </div>

            <div>
              <img src={Image2} />
            </div>
            <div>
              <img src={Image3} />
            </div>
          </Carousel>
        </div>
        
        

      </>
    );
  }
}
