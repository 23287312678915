import React, { Component } from "react";
import { NavLink } from "react-router-dom";
class CandidateSection extends Component {
  render() {
    return (
      <>
        {/* Candidate Section */}
        <section id="Candidate" className="our_service padding ptb-xs-40">
          <div className="container">
            <div
              className="row pb-30 text-center"
              data-aos="fade-down"
              data-aos-duration="800"
              data-aos-offset="100"
            >
              <div className="col-sm-12 mb-20">
                <h2>
                  <span>Find The</span> Candidates. Right Now
                </h2>
                <span className="b-line" />
              </div>
            </div>
            <div className="row">
              
              <div
                className="col-lg-4 col-md-4 col-sm-4"
                data-aos="fade"
                data-aos-duration="900"
                data-aos-offset="110"
              >
                <div className="service_box hi-icon-effect-3 hi-icon-effect-3b">
                  <div className="service_icon">
                    <i className="hi-icon fa fa-user-plus" aria-hidden="true" />
                  </div>
                  <div className="service_details">
                    <p className="font-color_base no_stripe">
                      <NavLink to="/Login">
                        CREATE AN ACCOUNT
                        <br />
                      </NavLink>
                    </p>
                  </div>
                  <div className="service_content text-justify">
                    <p style={{ fontSize: 12, fontWeight: "normal" }}>
                      Start your career journey with OneCloud Services by
                      creating an account. Gain access to personalized job
                      recommendations, job alerts, and easily manage your
                      applications.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-4 col-sm-4"
                data-aos="fade"
                data-aos-duration="800"
                data-aos-offset="110"
              >
                <div className="service_box hi-icon-effect-3 hi-icon-effect-3b">
                  <div className="service_icon">
                    <i className="hi-icon fa fa-database" aria-hidden="true" />
                  </div>

                  <div className="service_details">
                    <p className="font-color_base no_stripe">
                      <NavLink to={"/Login"}>CV DATABASE</NavLink>
                    </p>
                  </div>
                  <div className="service_content text-justify">
                    <p style={{ fontSize: 12, fontWeight: "normal" }}>
                      Discover qualified candidates effortlessly with our
                      extensive CV database. Search and filter based on skills,
                      experience, and more to find the perfect fit for your
                      organization.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-4 col-sm-4"
                data-aos="fade"
                data-aos-duration="800"
                data-aos-offset="110"
              >
                <div className="service_box hi-icon-effect-3 hi-icon-effect-3b">
                  <div className="service_icon">
                    <i className="hi-icon fa fa-glass" aria-hidden="true" />
                  </div>
                  <div className="service_details">
                    <p className="font-color_base no_stripe">
                      <NavLink to="/Login">FILL UP THE VACANCY</NavLink>
                    </p>
                  </div>
                  <div className="service_content text-justify">
                    <p style={{ fontSize: 12, fontWeight: "normal" }}>
                      Simplify your hiring process by posting a job with
                      OneCloud Services. Our experienced recruiters will screen
                      candidates and present you with the best-suited talent for
                      your vacancy.
                    </p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default CandidateSection;
