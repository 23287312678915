import { api_instance } from "./api";


//jobtype

export const jobTypeList = () => {
  return  api_instance.get("/jobType.php");
};

export const jobTypeById = (JobTypeId) => {
  return api_instance.get(`/jobType.php?JobTypeId=${JobTypeId}`);
};

//jobCategory

export const jobCategoryList = () => {
  return  api_instance.get("/jobCategory.php");
};

export const jobCategoryListById = (JobCategoryId) => {
  return api_instance.get(`/jobCategory.php?categoryId=${JobCategoryId}`);
};


//jobCategory

export const jobSkillList = () => {
  return  api_instance.get("/skillset.php");
};

export const jobSkillByName = (skillName) => {
  return api_instance.get(`/skillset.php?skillName=${skillName}`);
};


//jobCategory

export const jobLocationList = () => {
  return  api_instance.get("/location_list.php");
};

export const jobLocationListById = (JobLocationId) => {
  return api_instance.get(`/location_list.php?locationId=${JobLocationId}`);
};

// jobtitle

export const jobTitleList = (title) => {
  return api_instance.get(`/job_title.php?title=${title}`);
};


//jobs


export const jobList = () => {
  return api_instance.get("/jobDetails.php");
};

export const jobDetails = (JobId) => {
  return api_instance.get(`/jobDetails.php?jobId=${JobId}`);
};

export const jobDetailsByJobTypeId = (JobTypeId) => {
  return api_instance.get(`/jobDetails.php?searchParam=${JobTypeId}`);
};

export const jobListByTitle = (title) => {
  return api_instance.get(`/jobDetails.php?searchParam=${title}`);
};

export const jobListBySkill = (skillId) => {
  return api_instance.get(`/jobDetails.php?searchParam=${skillId}`);
};













