import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class Training extends Component {
  render() {
    return (
      <>
        {/* Intro Section */}
        <section
           data-aos="fade-up" data-aos-duration="800"
          className="inner-intro padding"
          style={{
            background:
              "url(assets/images/services/training.jpg)",
            backgroundSize: "cover",
            left: 0,
            top: 0,
            width: "100%",
            height:'600px',
            zIndex: -1,
          }}
        >
          <div className="container"  data-aos="fade-down" data-aos-duration="1000">
            <div className="row title">
              <h1 data-title="Leadership">
                <span style={{ color: "#FFF" }}>Training & Consultation Service</span>
              </h1>
            </div>
          </div>
        </section>
        {/* Intro Section */}
        <section className="padding ptb-xs-60">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-xs-40 pb-xs-40"  data-aos="fade-up" data-aos-duration="800" data-aos-offset="100">
                <div className="tab-title">
                  <ul className="clearfix">
                    <li>
                      <NavLink to={"/Service"}>Our Services</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Contracting"}>
                        Contracting Services
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Permanent"}>Permanent Services</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Temporary"}>
                        Temporary Agency Staffing
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/HomeCare"}>HomeCare Services</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Overseas"}>Overseas Recruitment</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Training"} className="active">
                        Traning & Consultation
                      </NavLink>
                    </li>

                   
                  </ul>
                </div>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12"  data-aos="fade-down" data-aos-duration="800" data-aos-offset="100">
                <div className="tab-content">
                  <div className="row">
                    <div className="col-sm-12" style={{ top: -15 }}>
                      <img
                        src="assets/images/services/training.jpg"
                        alt="Training & Consultation Services"
                      />
                    </div>
                    <div className="col-sm-12">
                      <h2 className="pt-30">Training & Consultation Service</h2>
                      <p className="text-justify text-content">
                        <hr/>
                       <b> ENHANCE YOUR SKILLS, BUILD YOUR FUTURE:</b> <hr/>OneCloud is
                        dedicated to assisting you in finding the perfect
                        opportunity, at the ideal time. Our expert and skilled
                        recruitment consultants collaborate with you to advance
                        your career and achieve your aspirations.
                        <hr/>
                       <b> IMAGINE YOUR DREAMS. LET'S NOW ACCOMPLISH IT.</b> <hr/>We have
                        the ability to link you with suitable individuals and
                        opportunities, regardless of the level, whether it is
                        temporary or permanent. We will guide you in meeting key
                        individuals at top-level companies. All it takes is one
                        chance to begin a transformation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
