import React, { Component } from "react";
import withRouter from "../utils/withRouter";

import { NavLink } from "react-router-dom";

class Login extends Component {
  componentDidMount() {
    console.log(this.props.location.state);
  }

  render() {
    return (
      <>
        {/* Login Section */}
        <div
          id="login"
          className="ptb ptb-xs-60"
          data-aos="slide-up"
          data-aos-duration="1500"
        >
          <div
            className="container"
            style={{ boxShadow: "1px 2px 30px 40px #CCCEEE" }}
          >
            <div className="row">
              <div
                className="main-body"
                style={{ padding: 30, background: "#FFF" }}
              >
                <div className="body-inner">
                  <div className="card bg-white">
                    <div className="card-content">
                      <section className="logo text-center">
                        <h2
                          style={{
                            backgroundImage:
                              "linear-gradient(270deg,#AF40FF, #0f64db 50%,#00DDEB)",
                            padding: 10,
                            color: "#FFF",
                            borderRadius: 5,
                          }}
                        >
                          Login To OneCloud Services
                        </h2>
                      </section>
                      <form
                        className="form-horizontal ng-pristine ng-valid"
                        style={{ padding: 5 }}
                      >
                        <fieldset>
                          <div className="form-group" style={{ marginTop: 10 }}>
                            <input
                              type="radio"
                              value="Male"
                              name="gender"
                              style={{ margin: 10 }}
                            />
                            <label>Employer</label>
                            <input
                              type="radio"
                              value="Female"
                              name="gender"
                              style={{ margin: 10 }}
                            />
                            <label>JobSeeker</label>
                          </div>
                          <br />

                          <div className="form-group">
                            <div className="ui-input-group">
                              <input
                                type="text"
                                required=""
                                className="form-control"
                              />
                              <span className="input-bar" />
                              <label>Email</label>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="ui-input-group">
                              <input
                                type="text"
                                required=""
                                className="form-control"
                              />
                              <span className="input-bar" />
                              <label>Password</label>
                            </div>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                    <div
                      className="card-action no-border text-right"
                      style={{
                        backgroundImage:
                          "linear-gradient(360deg,#AF40FF, #0f64db 50%,#0f64db)",
                      }}
                    >
                      <NavLink
                        to="/Login"
                        className="button-56"
                        style={{
                          color: "#FFF",
                        }}
                      >
                        Sign in
                      </NavLink>
                    </div>
                  </div>
                  <div className="additional-info">
                    <NavLink
                      to="/Register"
                      className="btn btn-sm"
                      style={{
                        background: "#d81a1a",
                        color: "#FFF",
                        fontWeight: "600",
                      }}
                    >
                      Register an account
                    </NavLink>
                    <span className="divider-h"></span>
                    <NavLink
                      to="/ForgotPassword"
                      className="btn btn-sm pull-right"
                      style={{
                        background: "#d81a1a",
                        color: "#FFF",
                        fontWeight: "500",
                      }}
                    >
                      Forgot your password ?{" "}
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Login Section */}
      </>
    );
  }
}

export default withRouter(Login);
