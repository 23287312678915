import React from 'react';
import './css/JobCard.css';
import { FaEuroSign } from 'react-icons/fa';

const JobCardComponent = ({ jobTitle, company, location, jobType, experienceLevel, description }) => {
  return (
    <div className="job-card">
      <div className="job-info">
        <h2 className="job-title">{jobTitle}</h2>
        <h3 className="company">{company}</h3>
        <p className="location">{location}</p>
        <div className="tags">
          <span className="tag">{jobType}</span>
          <span className="tag"><FaEuroSign /> {experienceLevel} / <sup>Annual</sup></span>
        </div>
        <p className="description">{description}</p>
      </div>
      <div className="apply-button">
        <button>Apply Now</button>
      </div>
    </div>
  );
}

export default JobCardComponent;
