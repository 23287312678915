import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'

export class ForgotPassword extends Component {
  render() {
    return (
        <>
        {/* Login Section */}
        <div id="login" className="ptb ptb-xs-60" >
          <div className="container" style={{boxShadow:"1px 2px 30px 40px #CCCEEE"}}>
            <div className="row">
              <div className="main-body" style={{padding:30 }}>
                <div className="body-inner">
                  <div className="card bg-white">
                    <div className="card-content">
                      <section className="logo text-center">
                        <h2 style={{background:'#d81a1a',padding:10,color:'#FFF', borderRadius:5}}>Look Like You forgot Your Password huh!....</h2>
                      </section>
                      <form className="form-horizontal ng-pristine ng-valid" style={{padding:5}} >
                        <fieldset>
                          <div className="form-group" style={{marginTop:10}}>
                            <input type="radio" value="Male" name="gender" style={{margin:10}}/>
                            <label>Employer</label>
                            <input
                              type="radio"
                              value="Female"
                              name="gender"
                              style={{margin:10,}}
                            />
                            <label>JobSeeker</label>
                          </div>
                          <br/>

                          <div className="form-group">
                            <div className="ui-input-group">
                              <input
                                type="text"
                                required=""
                                className="form-control"
                              />
                              <span className="input-bar" />
                              <label>Email</label>
                            </div>
                          </div>

                        </fieldset>
                      </form>
                    </div>
                    <div
                      className="card-action no-border text-right"
                      style={{ backgroundColor: "#3848a2" }}
                    >
                      <NavLink
                        to="/Login"
                        style={{
                          color: "#FFF",
                        }}
                      >
                        SUBMIT
                      </NavLink>
                    </div>
                  </div>
                  <div className="additional-info">
                   <NavLink to="/Register" className="btn btn-sm" style={{background:'#d81a1a', color:'#FFF',fontWeight:'500',}}>Register an account</NavLink>
                    <span className="divider-h"></span>
                    <NavLink to="/Login" className="btn btn-sm pull-right" style={{background:'#d81a1a', color:'#FFF',fontWeight:'500',}}>Login..  </NavLink>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Login Section */}
      </>
    )
  }
}

export default ForgotPassword