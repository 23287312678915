import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class CapitalMarket extends Component {
  render() {
    return (
      <>
        {/* Intro Section */}
        <section 
         data-aos="fade-up" data-aos-duration="800"
          className="inner-intro padding"
          style={{
            background:
              "url(assets/images/services/Capital_2000.png)",
            backgroundSize: "cover",
            left: 0,
            top: 0,
            width: "100%",
            height:'600px',
            zIndex: -1,
          }}
        >
          <div className="container"  data-aos="fade-up" data-aos-duration="800">
            <div className="row title">
              <h1 style={{ color: "#CD1818" }}>Capital Market</h1>
              {/* <div className="page-breadcrumb">
                <span style={{ color: "#CD1818" }}>Industries</span>
              </div> */}
            </div>
          </div>
        </section>
        {/* End Intro Section */}
        {/* Service Section */}
        <div id="services-section" className="padding pt-xs-60">
          <div className="container">
            <div className="row">
             
              <div className="col-lg-4 col-md-4 col-sm-12 col-sx-12"  data-aos="fade-down" data-aos-duration="800" data-aos-offset="100">
                <div className="single-sidebar-widget">
                  <div className="special-links">
                    <ul>
                      <li>
                        <NavLink to={"/Industries"}>
                          Our Industries
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/HealthCare">HealthCare</NavLink>
                      </li>
                      <li>
                        <NavLink to="/HomeCare">HomeCare</NavLink>
                      </li>
                      <li>
                        <NavLink to="/Financial">Financial</NavLink>
                      </li>
                      <li>
                        <NavLink to="/Banking">Banking</NavLink>
                      </li>
                      <li>
                        <NavLink className="active" to="/CapitalMarket">
                          Capital Market
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/InformationTechnology">
                          Information Technology
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12"  data-aos="fade-down" data-aos-duration="800" data-aos-offset="100">
                <div className="full-pic">
                  <figure>
                    <img
                      src="assets/images/services/Capital_500.png"
                      alt="Capital Markets Industries"
                    />
                  </figure>
                </div>
                <div className="text-box mt-40">
                  <div className="box-title mb-20">
                    <h2>Capital Market Industries</h2>
                  </div>
                  <div className="text-content text-justify">
                    <p>
                      The capital market refers to a sophisticated financial
                      ecosystem where a wide range of financial securities,
                      including stocks, bonds, derivatives, and commodities, are
                      traded. It serves as a platform for companies and
                      governments to raise capital by issuing these securities
                      to investors. The capital market plays a crucial role in
                      facilitating efficient allocation of funds and promoting
                      economic growth.
                    </p>
                    <p  data-aos="fade-up" data-aos-duration="800" data-aos-offset="100">
                      <h3 style={{ color: "#CD1818",fontSize:18,fontWeight:'900' }}>OneCloud Services</h3>
                      OneCloud Services is a reputable agency specializing in
                      talent acquisition and placement services within the
                      capital market and financial industry. With an unwavering
                      commitment to excellence, OneCloud Services stands out as
                      an invaluable partner for companies seeking top-tier
                      professionals and for individuals looking to advance their
                      careers within this dynamic sector.
                    </p>

                    <p  data-aos="fade-up" data-aos-duration="800" data-aos-offset="110">
                      <h4>Why Choose OneCloud Services:</h4>
                      <b>1. Specialized Expertise: </b> At OneCloud Services, we have
                      honed our focus exclusively on the capital market and
                      financial industry. This specialization allows us to
                      develop an unrivaled understanding of the industry's
                      nuances, trends, and evolving talent requirements. By
                      leveraging our in-depth knowledge, we are adept at
                      identifying and matching highly qualified candidates with
                      the specific needs of employers. 
                      <br/><br/>
                      <b>2. Extensive Network: </b>
                      Over the years, OneCloud Services has cultivated an
                      extensive network of professionals within the capital
                      market and financial industry. This network comprises
                      exceptional talent with specialized skills, expertise, and
                      experience sought after by employers in this competitive
                      sector. Our deep connections enable us to access a vast
                      pool of exceptional candidates, often beyond the reach of
                      traditional recruitment methods. 
                      <br/><br/>
                      <b>3. Industry Insights: </b>Our
                      team of seasoned professionals possesses a wealth of
                      industry insights, staying abreast of the latest market
                      trends, regulatory changes, and emerging job roles within
                      the capital market and financial sector. We serve as
                      trusted advisors to both employers and candidates,
                      providing strategic guidance to navigate the intricacies
                      of the industry and make informed decisions. 
                      <br/><br/>
                      <b>4. Tailored Approach: </b>At OneCloud Services, we believe in a
                      personalized and tailored approach to recruitment. We
                      invest considerable time and effort in understanding the
                      unique requirements of our clients and the career
                      aspirations of our candidates. By carefully aligning the
                      expectations of both parties, we deliver optimal outcomes
                      by matching candidates who possess the right combination
                      of skills, experience, and cultural fit to the available
                      job opportunities. 
                      <br/><br/>
                      <b>5. Value-added Services: </b>In addition to
                      our core recruitment services, OneCloud Services offers a
                      range of value-added services to enhance the hiring
                      process. These include candidate screening, comprehensive
                      skills assessments, career counselling, and customized
                      training programs. Our commitment to delivering holistic
                      solutions sets us apart from other recruitment agencies
                      and ensures our clients receive exceptional support at
                      every stage.
                    </p>
                    <p>
                    Partner with OneCloud Services today and unlock the full potential of your talent acquisition strategy. Experience first-hand why our unparalleled expertise, extensive network, and tailored approach have made us the preferred choice for companies and professionals in the capital market and financial industry.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Service Section end */}
      </>
    );
  }
}
