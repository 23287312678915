import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class Financial extends Component {
  render() {
    return (
      <>
        {/* Intro Section */}
        <section
          data-aos="fade-down"
          data-aos-duration="800"
          className="inner-intro padding"
          style={{
            background: "url(assets/images/services/finance.png)",
            backgroundSize: "cover",
            left: 0,
            top: 0,
            width: "100%",
            height: "600px",
            zIndex: -1,
          }}
        >
          <div className="container" data-aos="fade-up" data-aos-duration="800">
            <div className="row title">
              <h1 style={{ color: "#CD1818" }}>Financial</h1>
              {/* <div className="page-breadcrumb">
                <span style={{ color: "#CD1818" }}>Industries</span>
              </div> */}
            </div>
          </div>
        </section>
        {/* End Intro Section */}

        {/* Service Section */}
        <div id="services-section" className="padding pt-xs-60">
          <div className="container">
            <div className="row">
              
              <div
                className="col-lg-4 col-md-4 col-sm-12 col-sx-12"
                data-aos="fade-down"
                data-aos-duration="800"
                data-aos-offset="100"
              >
                <div className="single-sidebar-widget">
                  <div className="special-links">
                    <ul>
                      <li>
                        <NavLink to={"/Industries"}>Our Industries</NavLink>
                      </li>
                      <li>
                        <NavLink to="/HealthCare">HealthCare</NavLink>
                      </li>
                      <li>
                        <NavLink to="/HomeCare">HomeCare</NavLink>
                      </li>
                      <li>
                        <NavLink className="active" to="/Financial">
                          Financial
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/Banking">Banking</NavLink>
                      </li>
                      <li>
                        <NavLink to="/CapitalMarket">Capital Market</NavLink>
                      </li>

                      <li>
                        <NavLink to="/InformationTechnology">
                          Information Technology
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="single-sidebar-widget">
                  <div className="sec-title">
                    <h2>
                      Download<span> brochure</span>
                    </h2>
                    <span>Here is some useful documents</span>
                  </div>
                  <ul className="brochure-btns">
                    <li>
                      <a href="#" className="clearfix">
                        <i className="fa fa-file-pdf-o" />{" "}
                        <span>Download Brochure</span>{" "}
                      </a>
                    </li>
                    <li>
                      <a href="#" className="clearfix">
                        <i className="fa fa-file-pdf-o" />{" "}
                        <span>Download Brochure</span>
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>

              <div
                className="col-lg-8 col-md-8 col-sm-12 col-xs-12"
                data-aos="fade-down"
                data-aos-duration="800"
                data-aos-offset="100"
              >
                <div className="full-pic">
                  <figure>
                    <img
                      src="assets/images/services/finance_500.png"
                      alt="Financial Industries"
                    />
                  </figure>
                </div>
                <div className="text-box mt-40">
                  <div className="box-title mb-20">
                    <h2>Financial Industries</h2>
                  </div>
                  <div className="text-content text-justify">
                    <p>
                      Welcome to OneCloud Services, your premier partner in the
                      thriving world of the financial industry. As a reputable
                      recruitment agency, we specialize in providing tailored
                      solutions and sourcing top-tier talent for the financial
                      sector. In this section, we offer comprehensive insights
                      into the financial industry, its key sectors, prominent
                      job roles, and emerging trends that shape its future.
                    </p>
                    <h3
                      data-aos="fade-down"
                      data-aos-duration="800"
                      data-aos-offset="110"
                      style={{ color: "#CD1818" }}
                    >
                      Overview of the Financial Industry
                    </h3>
                    <p
                      data-aos="fade-down"
                      data-aos-duration="800"
                      data-aos-offset="110"
                    >
                      The financial industry refers to a broad sector of the
                      economy that encompasses various institutions, companies,
                      and professionals involved in managing and facilitating
                      the flow of money, capital, and financial products. It
                      includes both traditional and modern financial activities,
                      such as banking, insurance, asset management, investment
                      banking, private equity, venture capital, and fintech.
                    </p>
                    <ol
                      data-aos="fade-down"
                      data-aos-duration="800"
                      data-aos-offset="110"
                    >
                      <li>
                        <strong>Banking :</strong> Banks are financial
                        institutions that provide a wide range of financial
                        services, such as accepting deposits, granting loans,
                        facilitating payments, issuing credit cards, and
                        offering various investment products. They play a
                        crucial role in the economy by providing liquidity,
                        safeguarding deposits, and facilitating economic
                        transactions.
                      </li>
                      <li>
                        <strong>Insurance : </strong>Insurance companies offer
                        protection against financial losses by providing
                        coverage for individuals and businesses in exchange for
                        premiums. They manage risks and provide financial
                        compensation in the event of specified events, such as
                        accidents, property damage, illness, or death.
                      </li>
                      <li>
                        <strong>Asset Management : </strong>Asset management
                        involves professionally managing and investing assets on
                        behalf of individuals, institutions, or funds. Asset
                        managers create and manage investment portfolios
                        consisting of various financial instruments like stocks,
                        bonds, real estate, and commodities, aiming to generate
                        returns for their clients.
                      </li>
                      <li>
                        <strong>Investment Banking : </strong>Investment banks
                        provide a range of financial services to corporations,
                        governments, and other entities. These services include
                        underwriting new securities issuances (such as stocks
                        and bonds), facilitating mergers and acquisitions,
                        providing advisory services, and assisting in raising
                        capital through public or private markets.
                      </li>
                      <li>
                        <strong>Private Equity : </strong>Private equity firms
                        invest in private companies by purchasing ownership
                        stakes. They raise funds from institutional investors
                        and high-net- worth individuals, aiming to generate
                        significant returns by improving the operations, growth,
                        and profitability of the acquired companies.
                      </li>
                      <li>
                        <strong>Venture Capital : </strong>Venture capital (VC)
                        firms provide financing and support to early-stage and
                        high-potential startups. They invest in these companies
                        in exchange for an ownership stake, with the expectation
                        of substantial returns when the startups become
                        successful and go public or are acquired by larger
                        companies.
                      </li>
                      <li>
                        <strong>Fintech : </strong>Fintech, short for financial
                        technology, refers to the application of technology and
                        innovation to improve and automate financial services.
                        Fintech companies offer various digital platforms,
                        mobile apps, and online services that facilitate
                        banking, payments, lending, investing, and other
                        financial activities, often challenging traditional
                        financial institutions.
                      </li>
                    </ol>
                    <br />
                    <p
                      data-aos="fade-up"
                      data-aos-duration="800"
                      data-aos-offset="110"
                    >
                      Global Significance: The financial industry plays a
                      pivotal role in the global economy. It serves as a bridge
                      between savers and borrowers, facilitates capital
                      allocation, and manages financial risks. Here are some key
                      points illustrating its significance:
                    </p>

                    <ol
                      data-aos="fade"
                      data-aos-duration="800"
                      data-aos-offset="110"
                    >
                      <li>
                        <strong>Capital Flow : </strong>
                        The financial industry enables the flow of capital from
                        individuals and institutions with surplus funds (savers)
                        to those who need funds for investment, expansion, or
                        consumption (borrowers). This capital allocation is
                        crucial for economic growth, job creation, and
                        innovation.
                      </li>
                      <li>
                        <strong>Risk Management : </strong>
                        Financial institutions provide a range of products and
                        services that help manage financial risks. Insurance
                        companies protect against unforeseen events, banks
                        provide loans and credit facilities to manage liquidity,
                        and asset managers diversify investments to reduce
                        risks. Efficient risk management is vital for the
                        stability of the global economy.
                      </li>
                      <li>
                        <strong>Employment and Economic Contribution : </strong>
                        The financial industry is a significant employer
                        worldwide, creating jobs for professionals such as
                        bankers, investment analysts, insurance agents, and
                        financial advisors. It also contributes to the economy
                        through taxes, fees, and the generation of wealth.
                      </li>
                      <li>
                        <strong>Market Efficiency : </strong>
                        Financial markets, including stock exchanges, bond
                        markets, and commodity markets, enable the buying and
                        selling of financial assets. These markets provide
                        transparency, liquidity, and price discovery mechanisms,
                        facilitating efficient allocation of capital and
                        fostering economic growth.
                      </li>
                      <li>
                        <strong>Global Interconnectedness : </strong>
                        The financial industry operates across borders,
                        connecting different countries and economies.
                        International banks facilitate global trade and
                        cross-border transactions, while investment firms invest
                        in companies and projects
                      </li>
                    </ol>

                    <h3
                      data-aos="fade"
                      data-aos-duration="800"
                      data-aos-offset="110"
                      style={{ color: "#CD1818" }}
                    >
                      Key Sectors within the Financial Industry :
                    </h3>
                    <ul
                      data-aos="fade-down"
                      data-aos-duration="800"
                      data-aos-offset="110"
                    >
                      <li>
                        <strong>
                          Consumer &amp; Commercial Financial Services :{" "}
                        </strong>
                        The banking and financial services industry is
                        undergoing significant changes due to the rise of
                        technology and FinTech. This has resulted in the need
                        for banks to adopt new business models and find leaders
                        who can bridge the gap between finance and technology.
                        OneCloud Services can help banking and FinTech companies
                        find the next generation of leaders and build a strong
                        leadership pipeline to optimise productivity and
                        profitability.
                      </li>
                      <li>
                        <strong>
                          Corporate &amp; Investment Banking / Global Markets :{" "}
                        </strong>
                        The Corporate &amp; Investment Banking/Global Markets
                        industry is highly competitive and constantly changing.
                        It is essential for firms to identify, recruit, and
                        develop top-tier talent in order to succeed. OneCloud
                        Services has consultants who specialise in Investment
                        Banking, Corporate Banking, and Global Markets, and can
                        help organisations locate, attract, engage, and retain
                        the best talent capable of thriving in fast-paced,
                        ever-changing environments.
                      </li>
                      <li>
                        <strong> Insurance : </strong>
                        The insurance industry is facing various risks,
                        including technological advancements, data, analytics,
                        changing customer expectations, low-interest rates,
                        demographic changes, increased competition,
                        consolidation, and catastrophes. OneCloud can help
                        insurers develop comprehensive people and organisational
                        strategies, identifying future leaders, promoting
                        development, accelerating readiness, and facilitating
                        transitions to overcome these obstacles and achieve
                        their business objectives.
                      </li>
                      <li>
                        <strong> Investment Management : </strong>
                        Investment management consulting is evolving rapidly,
                        with intense competition and numerous mergers and
                        acquisitions. One Cloud can assist asset managers, hedge
                        funds, wealth managers, and alternative investment firms
                        in hiring, developing, and retaining a workforce capable
                        of thriving in a fast-paced and ever-changing
                        environment.
                      </li>

                      <li>
                        <strong> Private Equity : </strong>
                        Private equity firms are facing increasing
                        talent-related challenges due to the industry&#39;s
                        competitiveness. OneCloud can assist private equity
                        firms in executing talent strategy initiatives, placing
                        investment professionals, capital raisers, operating
                        partners, and other essential roles, and acting as
                        relationship managers and sponsor coverage advisors for
                        private equity portfolios.
                      </li>
                    </ul>

                    <p>
                      At OneCloud Services, we combine our expertise and
                      industry knowledge to connect exceptional talent with
                      exceptional opportunities in the financial industry.
                      Whether you are seeking top-tier professionals or aiming
                      to advance your career in this dynamic sector, we are here
                      to support you every step of the way. Contact us today to
                      discover how OneCloud Services can elevate your success in
                      the financial industry.
                    </p>
                  </div>
                  {/*                   
                  <div className="row mt-45 clearfix">
                    <div className="col-md-5">
                      <img
                        className="img-responsive"
                        src="assets/images/project/project2.jpg"
                        alt="Photo"
                      />
                    </div>
                    <div className="col-md-7 text-box">
                      <div className="box-title mb-20 mt-sm-30 mt-xs-30">
                        <h3>Aenean suscipit eget mi act</h3>
                      </div>
                      <div className="text-content">
                        <p>
                          Aenean suscipit eget mi act fermentum phasellus
                          vulputate turpis tincidunt. Aenean suscipit eget..
                          Aenean suscipit eget mi act fermentum phasellus
                          vulputate turpis tincidunt. Aenean suscipit eget
                          Aenean suscipit eget mi act fermentum phasellus
                          vulputate turpis tincidunt. Aenean suscipit eget..
                        </p>
                      </div>
                    </div>
                  </div>
                   */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Service Section end */}
      </>
    );
  }
}
