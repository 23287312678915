import React, { Component } from "react";
//import team1 from "../assets/images/team/team1.jpg";
import { NavLink } from "react-router-dom";

export default class IndustriesSection extends Component {
  render() {
    return (
      <>
        {/* Services Section */}
        <section id="services" className="our_service padding ptb-xs-60">
          <div className="container">
            <div className="row pb-30 text-center" data-aos="fade-down" data-aos-duration="800" data-aos-offset="110">
              <div className="col-sm-12 mb-20">
                <h2>
                  <span>Our</span> Industries
                </h2>
                <span className="b-line" />
              </div>
            </div>
            <div className="row">

            <div className="col-sm-4" data-aos="fade-down" data-aos-duration="800" >
                <div className="service_box hi-icon-effect-3 hi-icon-effect-3b">
                  <div className="service_icon">
                    <i className="hi-icon fa fa-heart" aria-hidden="true" />
                  </div>
                  <div className="service_details">
                    <h5 className="font-color_base no_stripe">
                      <NavLink to="HealthCare">
                        HealthCare
                      </NavLink>
                    </h5>
                  </div>
                  <div className="service_content">
                    <p>
                      The healthcare industry is one of the most crucial sectors
                      in any country's economy.
                    </p>
                  </div>
                </div>
              </div>

            <div className="col-sm-4" data-aos="fade-down" data-aos-duration="800" >
                <div className="service_box hi-icon-effect-3 hi-icon-effect-3b">
                  <div className="service_icon">
                    <i className="hi-icon fa fa-home" aria-hidden="true" />
                  </div>
                  <div className="service_details">
                    <h5 className="font-color_base no_stripe">
                      <NavLink to="HomeCare">
                        HomeCare
                      </NavLink>
                    </h5>
                  </div>
                  <div className="service_content">
                    <p>
                      Welcome to Homecare by OneCloud Services. We are proud to
                      oﬀer the..
                    </p>
                  </div>
                </div>
              </div>

             
              <div className="col-sm-4" data-aos="fade-down" data-aos-duration="800" >
                <div className="service_box hi-icon-effect-3 hi-icon-effect-3b">
                  <div className="service_icon">
                    <i className="hi-icon fa fa-briefcase" aria-hidden="true" />
                  </div>
                  <div className="service_details">
                    <h5 className="font-color_base no_stripe">
                      <NavLink to="Financial">
                        Financial
                      </NavLink>
                    </h5>
                  </div>
                  <div className="service_content">
                    <p>
                      The Corporate & Investment Banking/Global Markets industry
                      is highly competitive and..
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-4" data-aos="fade-down" data-aos-duration="800" data-aos-offset="110">
                <div className="service_box hi-icon-effect-3 hi-icon-effect-3b">
                  <div className="service_icon">
                    <i className="hi-icon fa fa-bank" aria-hidden="true" />
                  </div>
                  <div className="service_details">
                    <h5 className="font-color_base no_stripe">
                      <NavLink to="Banking">
                        Banking
                      </NavLink>
                    </h5>
                  </div>
                  <div className="service_content">
                    <p>
                      The banking and financial services industry is undergoing
                      significant changes...
                    </p>
                  </div>
                </div>
              </div>


             

              <div className="col-sm-4" data-aos="fade-down" data-aos-duration="800" data-aos-offset="110">
                <div className="service_box hi-icon-effect-3 hi-icon-effect-3b">
                  <div className="service_icon">
                    <i
                      className="hi-icon fa fa-thin fa-globe"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="service_details">
                    <h5 className="font-color_base no_stripe">
                      <NavLink to="CapitalMarket">
                        Capital Market
                      </NavLink>
                    </h5>
                  </div>
                  <div className="service_content">
                    <p>
                      The capital market refers to a sophisticated financial
                      ecosystem where a wide traded.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-4" data-aos="fade-down" data-aos-duration="800" data-aos-offset="110">
                <div className="service_box hi-icon-effect-3 hi-icon-effect-3b">
                  <div className="service_icon ">
                    <i className="hi-icon fa fa-cube" aria-hidden="true" />
                  </div>
                  <div className="service_details">
                    <h5 className="font-color_base no_stripe">
                      <NavLink to="InformationTechnology">
                        Information Technology
                      </NavLink>
                    </h5>
                  </div>
                  <div className="service_content">
                    <p>
                      The technology industry is rapidly growing and has become
                      a critical part of the..
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
      </>
    );
  }
}
