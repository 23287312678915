import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class Temporary extends Component {
  render() {
    return (
      <>
        {/* Intro Section */}
        <section
          data-aos="fade"
          data-aos-duration="800"
          className="inner-intro"
          style={{
            background: "url(assets/images/services/Temporary_2000.png)",
            backgroundSize: "cover",
            left: 0,
            top: 0,
            width: "100%",
            height: "600px",
            zIndex: -1,
          }}
        >
          <div className="container" data-aos="fade-up" data-aos-duration="900">
            <div className="row title">
              <h1 data-title="Leadership">
                <span style={{ color: "#CD1818" }}>
                  Temporary Agency Staffing
                </span>
              </h1>
            </div>
          </div>
        </section>
        {/* Intro Section */}
        <section className="padding ptb-xs-60">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-xs-40 pb-xs-40"
                data-aos="fade-up"
                data-aos-duration="900"
                data-aos-offset="100"
              >
                <div className="tab-title">
                  <ul className="clearfix">
                    <li>
                      <NavLink to={"/Service"}>Our Services</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Contracting"}>
                        Contracting Services
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Permanent"}>Permanent Services</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Temporary"} className="active">
                        Temporary Agency Staffing
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/HomeCare"}>HomeCare Services</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Overseas"}>Overseas Recruitment</NavLink>
                    </li>

                    <li>
                      <NavLink to={"/Training"}>Traning</NavLink>
                    </li>
                  </ul>
                </div>
              </div>

              <div
                className="col-lg-8 col-md-8 col-sm-12 col-xs-12"
                data-aos="fade-down"
                data-aos-duration="900"
                data-aos-offset="100"
              >
                <div className="tab-content">
                  <div className="row">
                    <div className="col-sm-12" style={{ top: -15 }}>
                      <img
                        src="assets/images/services/Temporary_500.png"
                        alt="Temporary Agency Staffing Services"
                      />
                    </div>
                    <div className="col-sm-12">
                      <h2 className="pt-30">Temporary Recruitment Services</h2>
                      <p className="text-justify text-content">
                        <b> Welcome to our Temporary Recruitment services, </b>
                        where we specialize in helping businesses like yours
                        acquire temporary workforce solutions to meet your
                        specific needs. Whether you require skilled
                        professionals for a short-term project or need
                        additional resources to handle a sudden increase in
                        workload, we are here to assist you every step of the
                        way.
                        <br /> <br />
                        At OneCloud Services, we understand the importance of
                        finding the right temporary candidates who possess the
                        niche expertise and fresh perspectives your organization
                        requires. Our team of experienced recruiters is
                        dedicated to identifying and engaging top talent that
                        aligns with your project requirements and company
                        culture. With our extensive network and industry
                        knowledge, we have the insights necessary to address the
                        unique challenges you may face in your industry.
                      </p>
                      <p
                        className="text-justify text-content"
                        data-aos="fade-up"
                        data-aos-duration="900"
                        data-aos-offset="110"
                      >
                        We believe in the power of flexibility and agility when
                        it comes to temporary staffing solutions. Our goal is to
                        provide you with a streamlined recruitment process that
                        is efficient, cost-effective, and tailored to your
                        specific timeline. By leveraging our adaptable talent
                        networks, we can swiftly connect you with candidates who
                        possess the specialized skills you need, reducing the
                        time and effort involved in the recruitment process.
                        <br />
                        <br />
                        In addition to sourcing and selecting the right
                        candidates, we also take care of the administrative
                        tasks and procedures associated with temporary
                        recruitment. We offer externally managed pay and
                        timesheets, freeing up your valuable time and resources
                        so that you can focus on the core aspects of your
                        business. Our aim is to simplify the process for you,
                        ensuring a seamless experience from start to finish.
                      </p>
                      <p
                        className="text-justify"
                        data-aos="fade"
                        data-aos-duration="900"
                        data-aos-offset="110"
                      >
                        Whether you require just one skilled specialist or an
                        entire team of temporary professionals, we are committed
                        to being your lifelong partner in temporary recruitment.
                        We understand that your needs may evolve over time, and
                        we are dedicated to adapting our services to meet those
                        changing requirements. With our professional and
                        straightforward approach, you can trust us to deliver
                        the proficiency and abilities you demand in a temporary
                        workforce.
                        <br />
                        <br />
                        Choose OneCloud services for your Temporary Recruitment
                        needs, and experience the benefits of accessing niche
                        skill sets, enhancing flexibility, and gaining fresh
                        insights to drive your business forward. Contact us
                        today to discuss your temporary staffing requirements
                        and let us help you find the perfect candidates to make
                        an immediate impact on your organization.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-sm-12 faq-service mb-30 mt-30">
                <h3>Process</h3>

                <div className="panel-group service-faq faq" id="accordion">
                  <div className="panel panel-default panel-primary">
                    <div className="panel-heading">
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapse-1"
                          aria-expanded="true"
                        >
                          1. LISTEN
                          <i className="fa fa-plus collape-plus" />
                        </a>
                      </h4>
                    </div>
                    <div id="collapse-1" className="panel-collapse collapse in">
                      <div className="panel-body">
                        <p>
                          Our initial step involves meeting with you to gain a
                          deep understanding of your objectives, the specific
                          positions you wish to fill, and the distinctive
                          requirements of your open roles.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default panel-primary">
                    <div className="panel-heading">
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapse-2"
                          aria-expanded="false"
                        >
                          2. SEARCH,INTERVEW,PREPARE
                          <i className="fa fa-plus collape-plus" />
                        </a>
                      </h4>
                    </div>
                    <div id="collapse-2" className="panel-collapse collapse">
                      <div className="panel-body">
                        <p>
                          Utilizing our available resources, we will actively
                          search for qualified candidates and conduct interviews
                          to create a shortlist of potential candidates for a
                          final interview with you.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default panel-primary">
                    <div className="panel-heading">
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapse-3"
                          aria-expanded="false"
                        >
                          3. ASSESS & EVALUATE
                          <i className="fa fa-plus collape-plus" />
                        </a>
                      </h4>
                    </div>
                    <div id="collapse-3" className="panel-collapse collapse">
                      <div className="panel-body">
                        <p>
                          Following the conclusive interview stage, we will
                          undertake a thorough and comprehensive assessment and
                          evaluation process. This involves conducting reference
                          checks, skills assessments, background checks, and
                          other relevant evaluations to ensure that the
                          candidates are well-suited for the position.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default panel-primary">
                    <div className="panel-heading">
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapse-3"
                          aria-expanded="false"
                        >
                          4. OFFER, HIRE & ONBOARD
                          <i className="fa fa-plus collape-plus" />
                        </a>
                      </h4>
                    </div>
                    <div id="collapse-3" className="panel-collapse collapse">
                      <div className="panel-body">
                        <p>
                          Based on your preferences, we will either support or
                          lead the process of extending an offer to the selected
                          candidate, as well as provide assistance with their
                          onboarding procedures.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </section>
      </>
    );
  }
}
