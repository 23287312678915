import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userType: null,
  userData: null,
  is_logged_in: false,
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.userData = action.payload;
      state.userType = action.payload.userTypeId;
      state.is_logged_in = true;
    },
    logout: (state) => initialState,
  },
});

export const { loginSuccess, logout } = userSlice.actions;

const usersReducer = userSlice.reducer;
export default usersReducer;
