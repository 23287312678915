import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class HealthCare extends Component {
  render() {
    return (
      <>
        {/* Intro Section */}
        <section
          data-aos="fade-up"
          data-aos-duration="800"
          className="inner-intro padding"
          style={{
            background: "url(assets/images/services/HealthCare_2000.png)",
            backgroundSize: "cover",
            left: 0,
            top: 0,
            width: "100%",
            height: "600px",
            zIndex: -1,
          }}
        >
          <div className="container" data-aos="fade-up" data-aos-duration="800">
            <div className="row title">
              <h1 style={{ color: "#CD1818" }}>Health Care</h1>
            </div>
          </div>

        </section>
        {/* End Intro Section */}
        {/* Service Section */}
        <div id="services-section" className="padding pt-xs-60">
          <div className="container">
            <div className="row">

              <div
                className="col-lg-4 col-md-4 col-sm-12 col-sx-12"
                data-aos="fade-down"
                data-aos-duration="800"
                data-aos-offset="100"
              >
                <div className="single-sidebar-widget">
                  <div className="special-links">
                    <ul>
                      <li>
                        <NavLink to={"/Industries"}>
                          Our Industries
                        </NavLink>
                      </li>
                      <li>
                        <NavLink className="active" to="/HealthCare">
                          HealthCare
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/HomeCare">HomeCare</NavLink>
                      </li>
                      <li>
                        <NavLink to="/Financial">Financial</NavLink>
                      </li>
                      <li>
                        <NavLink to="/Banking">Banking</NavLink>
                      </li>
                      <li>
                        <NavLink to="/CapitalMarket">Capital Market</NavLink>
                      </li>
                      <li>
                        <NavLink to="/InformationTechnology">
                          Information Technology
                        </NavLink>
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-8 col-md-8 col-sm-12 col-xs-12"
                data-aos="Fade-up"
                data-aos-duration="800"
                data-aos-offset="100"
              >
                <div className="full-pic">
                  <figure>
                    <img
                      src="assets/images/services/HealthCare_500.png"
                      alt="Health Care Industries"
                    />
                  </figure>
                </div>
                <div className="text-box mt-40">
                  <div className="box-title mb-20">
                    <h2>Health Care Industries</h2>
                  </div>
                  <div className="text-content text-justify">
                    <p>
                      The healthcare industry is one of the most crucial sectors
                      in any country's economy. It plays a vital role in
                      ensuring the wellbeing of the population, particularly as
                      the aging population continues to grow. However, the
                      industry is facing several challenges, such as a lack of
                      staff, diversity, and heavy regulations. These challenges
                      can significantly affect the quality of care and patient
                      outcomes.
                    </p>
                    <p>
                      One of the most significant challenges facing the
                      healthcare industry is the lack of staff. With an aging
                      population, there is a growing demand for healthcare
                      services. However, there are not enough healthcare
                      professionals to meet this demand. This shortage of staff
                      can lead to increased wait times, decreased quality of
                      care, and increased stress for healthcare workers. The
                      healthcare industry is also becoming increasingly diverse,
                      with patients coming from a range of cultural and
                      linguistic backgrounds. This diversity presents unique
                      challenges, including communication barriers and cultural
                      differences that can impact the quality of care. It is
                      essential to have a diverse workforce that can provide
                      culturally sensitive care to all patients. Additionally,
                      the healthcare industry is heavily regulated, with strict
                      compliance requirements and standards that must be met.
                      Failing to meet these standards can lead to serious
                      consequences, such as fines or loss of accreditation. It
                      can be challenging to navigate these regulations,
                      particularly for smaller healthcare facilities.
                    </p>

                    <p
                      data-aos="fade"
                      data-aos-duration="800"
                      data-aos-offset="110"
                    >
                      These challenges can make it difficult for healthcare
                      facilities to find the right staff to provide high-quality
                      care. That's where OneCloud Services can help. OneCloud
                      services specializes in healthcare recruitment and can
                      provide healthcare facilities with the staff they need to
                      meet the growing demand for healthcare services. OneCloud
                      Services understands the challenges facing the healthcare
                      industry and can help healthcare facilities find the right
                      staff to meet their specific needs. They have a database
                      of qualified healthcare professionals from diverse
                      backgrounds who can provide culturally sensitive care to
                      all patients. OneCloud Services can also assist healthcare
                      facilities in navigating the complex regulations governing
                      the healthcare industry. They have a team of experts who
                      can provide guidance and support to ensure that facilities
                      are meeting compliance requirements and standards.
                    </p>
                    <p
                      data-aos="fade-down"
                      data-aos-duration="800"
                      data-aos-offset="110"
                    >
                      In conclusion, the healthcare industry is facing several
                      challenges, including a lack of staff, diversity, and
                      heavy regulations. However, with the help of OneCloud,
                      healthcare facilities can find the right staff to provide
                      high-quality care and navigate the complex regulatory
                      environment. Contact OneCloud Recruitment Agency today to
                      learn how they can help your healthcare facility overcome
                      these challenges and provide the best possible care to
                      your patients.
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        {/* Service Section end */}
      </>
    );
  }
}
