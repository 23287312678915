import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class Industries extends Component {
  render() {
    return (
      <>
        {/* Intro Section */}
        <section
        data-aos="fade-down" data-aos-duration="800"
          className="inner-intro padding"
          style={{
            background: "url(assets/images/Industries.png)",
            backgroundSize: "cover",
            left: 0,
            top: 0,
            width: "100%",
            height:'600px',
            zIndex: -1,
          }}
        >
          <div className="container" data-aos="fade-up" data-aos-duration="800">
            <div className="row title">
              <h1 style={{ color: "#FFF" }}>Our Industries</h1>
            </div>
          </div>
        </section>
        {/* Intro Section */}

        {/* Service Section */}
        <div id="services-section" className="pt-90 pb-20 pt-xs-60" data-aos="fade-up" data-aos-duration="800" data-aos-offset="400">
          <div className="container">
            <div className="row ">
              <div className="col-sm-12">
                <div className="heading-box pb-30">
                  <h2>
                    <span>Our</span> Industries
                  </h2>
                  <span className="b-line l-left" />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">

                <ul className="project-col-3 isotope" id="isotope">

                <li>
                    <div className="about-block img-scale mb-60 mb-xs-40 clearfix">
                      <figure>
                        <NavLink to="/HealthCare">
                          <img
                            className="img-responsive"
                            src="assets/images/services/HealthCare_500.png"
                            alt="Health Care Industries"
                          />
                        </NavLink>
                      </figure>
                      <div className="text-box mt-25">
                        <div className="box-title mb-15">
                          <h3 className="text-primary">HealthCare</h3>
                        </div>
                        <div className="text-content">
                          <p className="text-justify">
                            As the world continues to grapple with the realities
                            of a global pandemic, it has become increasingly
                            important to prioritise your wellbeing and physical
                            and mental health. This is where OneCloud’s
                          </p>
                          <NavLink to="/HealthCare" className="btn-text mt-15">
                            Read More
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="about-block img-scale mb-60 mb-xs-40 clearfix">
                      <figure>
                        <NavLink to="/HomeCare">
                          <img
                            className="img-responsive"
                            src="assets/images/services/HomeCare_500.png"
                            alt="HomeCare Industries"
                          />
                        </NavLink>
                      </figure>
                      <div className="text-box mt-25">
                        <div className="box-title mb-15">
                          <h3 className="text-primary">HomeCare</h3>
                        </div>
                        <div className="text-content">
                          <p>
                            OneCloud Care at home services for children and
                            young people are an increasingly popular way to
                            provide a safe and comfortable environment for those
                            with special..
                          </p>
                          <NavLink to="/HomeCare" className="btn-text mt-15">
                            Read More
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="about-block img-scale mb-60 mb-xs-40 clearfix">
                      <figure>
                        <NavLink to="/Financial">
                          <img
                            className="img-responsive"
                            src="assets/images/services/finance_500.png"
                            alt="Financial Industries"
                          />
                        </NavLink>
                      </figure>
                      <div className="text-box mt-25">
                        <div className="box-title mb-15">
                          <h3 className="text-primary">Financial</h3>
                        </div>
                        <div className="text-content">
                          <p className="text-justify">
                            The banking and financial services industry is
                            undergoing significant changes due to the rise of
                            technology and FinTech. This has resulted in the.........
                          </p>
                          <NavLink to="/Financial" className="btn-text mt-15">
                            Read More
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="about-block img-scale mb-60 mb-xs-40 clearfix">
                      <figure>
                        <NavLink to="/Banking">
                          <img
                            className="img-responsive"
                            src="assets/images/services/Banking_500.png"
                            alt="Banking Industries"
                          />
                        </NavLink>
                      </figure>
                      <div className="text-box mt-25">
                        <div className="box-title mb-15">
                          <h3 className="text-primary">Banking</h3>
                        </div>
                        <div className="text-content">
                          <p className="text-justify">
                            OneCloud Services can help banking and FinTech
                            companies find the next generation of leaders and
                            build a strong leadership pipeline to optimise
                            productivity and ......
                          </p>
                          <NavLink to="/Banking" className="btn-text mt-15">
                            Read More
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="about-block img-scale mb-60 mb-xs-40 clearfix">
                      <figure>
                        <NavLink to="/CapitalMarket">
                          <img
                            className="img-responsive"
                            src="assets/images/services/Capital_500.png"
                            alt="Capital Market"
                          />
                        </NavLink>
                      </figure>
                      <div className="text-box mt-25">
                        <div className="box-title mb-15">
                          <h3 className="text-primary">Capital Markets</h3>
                        </div>
                        <div className="text-content">
                          <p className="text-justify">
                            The Corporate & Investment Banking/Global Markets
                            industry is highly competitive and constantly
                            changing. It is essential for firms to identify,
                          </p>
                          <NavLink
                            to="/CapitalMarket"
                            className="btn-text mt-15"
                          >
                            Read More
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="about-block img-scale mb-60 mb-xs-40 clearfix">
                      <figure>
                        <NavLink to="/InformationTechnology">
                          <img
                            className="img-responsive"
                            src="assets/images/services/IT_500.png"
                            alt="Information Technology"
                          />
                        </NavLink>
                      </figure>
                      <div className="text-box mt-25">
                        <div className="box-title mb-15">
                          <h3 className="text-primary">
                            Information Technology
                          </h3>
                        </div>
                        <div className="text-content">
                          <p className="text-justify">
                            The technology industry is rapidly growing and has
                            become a critical part of the global economy. From
                            software development to hardware manufacturing,
                          </p>
                          <NavLink
                            to="/InformationTechnology"
                            className="btn-text mt-15"
                          >
                            Read More
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Service Section end */}
      </>
    );
  }
}
