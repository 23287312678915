import React, { useState, useRef } from "react";
import {
  FaEuroSign,
  FaUserShield,
  FaPercent,
  FaEnvelope,
  FaMobile,
  FaBuilding,
} from "react-icons/fa";
import emailjs from "@emailjs/browser";
import * as employer_services from "../utils/employer_services";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 650,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 6,
};

export default function CostCalculatorForm() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const form = useRef();
  const executeScroll = () => form.current.scrollIntoView({ block: "start" });

  const [formData, setFormData] = useState({
    role: "",
    salary: "",
    fullname: "",
    email: "",
    message: "",
    address: "",
    phonenumber: "",
    company: "",
    companyPerson: "",
  });

  const [percent, setPercent] = useState("");
  const [flat, setFlat] = useState("");
  const [selectedLabel, setSelectedlabel] = useState("");

  const toggleState = (e) => {
    console.log(e.target.value);
    setSelectedlabel(e.target.value);
  };

  const handlePercentage = (e) => {
    let flatValue = "";
    setFlat(flatValue);
    setPercent(e.target.value);
  };

  const handleFlat = (e) => {
    setPercent("0");
    setFlat(e.target.value);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleOpen();
  };

  const handleSubmitPercent = () => {
    handleClose();
    let payload = {};

    payload.role = formData.role;
    payload.salary = formData.salary;
    payload.fullname = formData.fullname;
    payload.email = formData.email;
    payload.message = formData.message;
    payload.address = formData.address;
    payload.phonenumber = formData.phonenumber;
    payload.company = formData.company;
    payload.companyPerson = formData.companyPerson;

    payload.selectedLabel = selectedLabel;
    payload.percent = percent;

    employer_services
      .costCalculator(payload)
      .then((res) => {
        console.log(res.data);
        navigate("/");
        alert(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    console.log(payload);

    // emailjs
    //   .sendForm(
    //     "service_mzef2ze",
    //     "template_xsy17mu",
    //     form.current,
    //     "xbjBwBMhMNgupU3L0"
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //       alert(
    //         "Your details has been submitted successfully. Will reach you as soon as possible.."
    //       );
    //       navigate("/");
    //     },
    //     (error) => {
    //       console.log(error.text);
    //       alert("Error Submitting Details...");
    //     }
    //   );
  };

  const handleSubmitFlat = () => {
    handleClose();

    let payload = {};

    payload.role = formData.role;
    payload.salary = formData.salary;
    payload.fullname = formData.fullname;
    payload.email = formData.email;
    payload.message = formData.message;
    payload.address = formData.address;
    payload.phonenumber = formData.phonenumber;
    payload.company = formData.company;
    payload.companyPerson = formData.companyPerson;

    payload.selectedLabel = selectedLabel;
    payload.flat = flat;

    employer_services
      .costCalculator(payload)
      .then((res) => {
        console.log(res.data);
        navigate("/");
        alert(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    console.log(payload);

    // emailjs
    //   .sendForm(
    //     "service_mzef2ze",
    //     "template_xsy17mu",
    //     form.current,
    //     "xbjBwBMhMNgupU3L0"
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //       alert(
    //         "Your details has been submitted successfully. Will reach you as soon as possible.."
    //       );
    //       navigate("/");
    //     },
    //     (error) => {
    //       console.log(error.text);
    //       alert("Error Submitting Details...");
    //     }
    //   );
  };

  return (
    <>
      <section
        style={{ height: "60px", background: "#0F64DB", color: "white" }}
      >
        <div className="container">
          <div className="row mt-10">
            <h3 data-title="Recruiting Cost Comparison Tool">
              <span>Recruiting Cost Comparison Tool</span>
            </h3>
          </div>
        </div>
      </section>

      <div
        className="faq ptb-60 ptb-xs-40"
        style={{ background: "#F8F8F8" }}
        ref={form}
      >
        <div className="container">
          <div className="row">
            <form onSubmit={handleSubmit} ref={form}>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 mb-20">
                <h3 style={{ borderBottom: "1px dotted black" }}>
                  <p>Company Details</p>
                </h3>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="input-group">
                      <span
                        className="input-group-addon"
                        id="basic-addon1"
                        style={{ background: "#FF0066", color: "#FFF" }}
                      >
                        <FaBuilding />
                      </span>
                      <input
                        required
                        pattern="^[A-Za-z0-9 ]{2,50}$"
                        type="text"
                        className="form-control"
                        placeholder="Company Name"
                        aria-describedby="basic-addon1"
                        id="company"
                        name="company"
                        value={formData.company}
                        onChange={handleChange}
                      />
                    </div>
                    <br />
                  </div>

                  <div className="col-sm-12">
                    <div className="input-group">
                      <span
                        className="input-group-addon"
                        id="basic-addon1"
                        style={{ background: "#FF0066", color: "#FFF" }}
                      >
                        <FaUserShield />
                      </span>
                      <input
                        required
                        pattern="^[A-Za-z0-9 ]{2,50}$"
                        type="text"
                        className="form-control"
                        placeholder="Your Position"
                        aria-describedby="basic-addon1"
                        id="companyPerson"
                        name="companyPerson"
                        value={formData.companyPerson}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* Calculator Details */}
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <h3 style={{ borderBottom: "1px dotted black" }}>
                  <p>Calculating Details</p>
                  <span style={{ fontSize: 14 }}>
                    Please give valid details..
                  </span>
                </h3>

                <div className="row">
                  <div className="col-sm-12">
                    <div className="input-group">
                      <span
                        className="input-group-addon"
                        id="basic-addon1"
                        style={{ background: "#FF6666", color: "#FFF" }}
                      >
                        <FaUserShield />
                      </span>
                      <input
                        required
                        pattern="^[A-Za-z0-9 ]{2,20}$"
                        type="text"
                        className="form-control"
                        placeholder="Enter the role you are Recruiting for ex: BA, QA, Lead..."
                        aria-describedby="basic-addon1"
                        id="role"
                        name="role"
                        value={formData.role}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-10">
                  <div className="col-sm-12">
                    <div className="input-group">
                      <span
                        className="input-group-addon"
                        id="basic-addon1"
                        style={{ background: "#0F64DB", color: "#FFF" }}
                      >
                        <FaEuroSign />
                      </span>
                      <input
                        pattern="^[0-9]{2,10}$"
                        required
                        type="text"
                        className="form-control"
                        placeholder="Tell us the average salary per annum for this role.."
                        aria-describedby="basic-addon1"
                        id="salary"
                        name="salary"
                        value={formData.salary}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <h4 className="title">
                      How are you currently charged for the recurting fee?
                    </h4>
                  </div>

                  <div className="col-sm-6">
                    <div className="input-group">
                      <input
                        style={{ background: "#FF6666", color: "white" }}
                        value="Percentage %"
                        readOnly
                        className="form-control"
                      />

                      <span className="input-group-addon">
                        <input
                          required
                          type="radio"
                          id="Percentage"
                          name="toggle"
                          value="Percentage"
                          onChange={toggleState}
                          // checked={selectedLabel === "Percentage"}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="input-group">
                      <input
                        style={{ background: "#0F64DB", color: "white" }}
                        value="Flat"
                        readOnly
                        className="form-control"
                      />
                      <span className="input-group-addon">
                        <input
                          required
                          type="radio"
                          id="Flat"
                          name="toggle"
                          value="Flat"
                          onChange={toggleState}
                          // checked={selectedLabel === "Flat"}
                        />
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  aria-hidden={selectedLabel !== "Percentage" ? true : false}
                >
                  <div className="row">
                    <div className="col-sm-12 mt-10">
                      <div className="input-group">
                        <span
                          className="input-group-addon"
                          style={{ background: "#4A55A2", color: "#FFF" }}
                          id="basic-addon1"
                        >
                          {percent} <FaPercent color={"white"} />
                        </span>
                        <div className="rangeSlider">
                          <input
                            type="hidden"
                            name="Total"
                            value={(percent * formData.salary) / 100}
                          />
                          <input
                            type="range"
                            className="form-control"
                            name="percent"
                            id="percent"
                            value={percent ? percent : ""}
                            min="8"
                            max="35"
                            onChange={handlePercentage}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div aria-hidden={selectedLabel !== "Flat" ? true : false}>
                  <div className="row">
                    <div className="col-sm-12 mt-10">
                      <div className="input-group">
                        <span className="input-group-addon" id="basic-addon1">
                          <FaEuroSign color={"red"} />
                        </span>
                        <input type="hidden" name="Total" value={flat} />
                        <input
                          required={selectedLabel === "Flat"}
                          pattern="^[0-9]{3,5}$"
                          type="text"
                          className="form-control"
                          placeholder="Please enter the amount you pay as a flat fee for each hiring"
                          aria-describedby="basic-addon1"
                          name="flat"
                          id="flat"
                          min="300"
                          value={flat}
                          onChange={handleFlat}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Contact Details */}
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <h3 style={{ borderBottom: "1px dotted black" }}>
                  <p>Contact Details</p>
                  <span style={{ fontSize: 14 }}>
                    Please give valid contact details to check the results
                  </span>
                </h3>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-group">
                      <span
                        className="input-group-addon"
                        id="basic-addon1"
                        style={{ background: "#FF6666", color: "#FFF" }}
                      >
                        @
                      </span>
                      <input
                        required
                        type="text"
                        pattern="^[A-Za-z ]{2,50}$"
                        className="form-control"
                        placeholder="Full Name"
                        aria-describedby="basic-addon1"
                        id="fullname"
                        name="fullname"
                        value={formData.fullname}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="input-group">
                      <span
                        className="input-group-addon"
                        id="basic-addon1"
                        style={{ background: "#FF6666", color: "#FFF" }}
                      >
                        <FaMobile />
                      </span>
                      <input
                        required
                        pattern="^[0-9]{10,15}$"
                        type="text"
                        className="form-control"
                        placeholder="Valid Phone Number"
                        aria-describedby="basic-addon1"
                        id="phonenumber"
                        name="phonenumber"
                        value={formData.phonenumber}
                        onChange={handleChange}
                        maxLength={15}
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-10">
                  <div className="col-sm-12">
                    <div className="input-group">
                      <span
                        className="input-group-addon"
                        id="basic-addon1"
                        style={{ background: "#0F64DB", color: "#FFF" }}
                      >
                        <FaEnvelope />
                      </span>
                      <input
                        required
                        type="email"
                        className="form-control"
                        placeholder="Valid email address"
                        aria-describedby="basic-addon1"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-10">
                  <div className="col-sm-6">
                    <textarea
                      required
                      className="form-control"
                      placeholder="Enter Address Here..."
                      id="address"
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="col-sm-6">
                    <textarea
                      className="form-control"
                      placeholder="Enter Message if any..."
                      id="message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-40">
                <button
                  type="submit"
                  className="btn btn-block"
                  style={{ background: "#FF6666", color: "white" }}
                  onClick={executeScroll}
                >
                  Generate Result
                </button>
              </div>
            </form>

            <div className="col-lg-12 col-md-12 col-sm-12 mt-15">
              {/* <Button
                type="submit"
                onClick={handleOpen}
                className="btn btn-block"
                style={{ background: "#FF6666", color: "white", fontSize: 12 }}
              >
                Generate Result
              </Button> */}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography
                    className="text-center"
                    id="modal-modal-title"
                    variant="h4"
                    component="h3"
                  >
                    Cost Calculation Details
                  </Typography>
                  <hr />

                  {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}> */}

                  <div className="row">
                    <div
                      aria-hidden={
                        selectedLabel !== "Percentage" ? true : false
                      }
                    >
                      <div className="col-lg-4 col-md-4">
                        <div className="pricing_table">
                          <div className="package_title">
                            <div className="pricing-top" style={{background:'#000',color:'#FFF'}}>
                              Recruitment Agency Cost
                            </div>
                          </div>

                          <div className="package_body bg-success">
                            <div className="price_feature">
                              <span
                                style={{
                                  color: "#000CCC",
                                  fontSize: 16,
                                  fontWeight: "600",
                                  textDecorationLine: "underline",
                                }}
                              >
                                Role
                              </span>
                              <span>
                                <p>
                                  <FaUserShield />
                                  &nbsp; {formData?.role}
                                </p>
                              </span>
                              <span
                                style={{
                                  color: "#000CCC",
                                  fontSize: 16,
                                  fontWeight: "600",
                                  textDecorationLine: "underline",
                                }}
                              >
                                Annual Salary
                              </span>
                              <span>
                                <p>
                                  <FaEuroSign />
                                  &nbsp; {formData?.salary}
                                </p>
                              </span>

                              <span
                                style={{
                                  color: "#000CCC",
                                  fontSize: 16,
                                  fontWeight: "600",
                                  textDecorationLine: "underline",
                                }}
                              >
                                Annual Expense
                              </span>
                              <span>
                                <p>
                                  <FaEuroSign />
                                  &nbsp; {(percent * formData?.salary) / 100}
                                </p>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4">
                        <div className="pricing_table" >
                          <div className="package_title">
                            <div
                              className="pricing-top"
                              style={{ background: "red", color: "#FFF" }}
                            >
                              Onecloud Service Cost
                            </div>
                          </div>

                          <div
                            className="package_body"
                            style={{ background: "#EDEEEE",}}
                          >
                            <div className="price_feature">
                              <span
                                style={{
                                  color: "#000",
                                  fontSize: 16,
                                  fontWeight: "600",
                                  textDecorationLine: "underline",
                                }}
                              >
                                Role
                              </span>
                              <span>
                                <p>
                                  <FaUserShield />
                                  &nbsp; {formData?.role}
                                </p>
                              </span>
                              <span
                                style={{
                                  color: "#000",
                                  fontSize: 16,
                                  fontWeight: "600",
                                  textDecorationLine: "underline",
                                }}
                              >
                                Annual Salary
                              </span>
                              <span>
                                <p>
                                  <FaEuroSign />
                                  &nbsp; {formData?.salary}
                                </p>
                              </span>

                              <span
                                style={{
                                  color: "#000",
                                  fontSize: 16,
                                  fontWeight: "600",
                                  textDecorationLine: "underline",
                                }}
                              >
                                Annual Expense
                              </span>
                              <span>
                                <p>
                                  <FaEuroSign />
                                  &nbsp; {(8 * formData.salary) / 100}
                                </p>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4">
                        <div className="pricing_table">
                          <div className="package_title">
                            <div
                              className="pricing-top"
                              style={{ background: "#4E4FEB", color: "#FFF" }}
                            >
                              Your Save on Recruitment
                            </div>
                          </div>

                          <div
                            className="package_body"
                            style={{ background: "#000",color:'#FFF' }}
                          >
                            <div className="price_feature">
                              <span
                                style={{
                                  color: "#4E4FEB",
                                  fontSize: 16,
                                  fontWeight: "600",
                                  textDecorationLine: "underline",
                                }}
                              >
                                Role
                              </span>
                              <span>
                                <p>
                                  <FaUserShield />
                                  &nbsp; {formData?.role}
                                </p>
                              </span>
                              <span
                                style={{
                                  color: "#4E4FEB",
                                  fontSize: 16,
                                  fontWeight: "600",
                                  textDecorationLine: "underline",
                                }}
                              >
                                Annual Salary
                              </span>
                              <span>
                                <p>
                                  <FaEuroSign />
                                  &nbsp; {formData?.salary}
                                </p>
                              </span>

                              <span
                                style={{
                                  color: "#4E4FEB",
                                  fontSize: 16,
                                  fontWeight: "600",
                                  textDecorationLine: "underline",
                                }}
                              >
                                Annual Expense
                              </span>
                              <span>
                                <p>
                                  <FaEuroSign />
                                  &nbsp;{" "}
                                  {(percent * formData.salary) / 100 -
                                    (8 * formData.salary) / 100}
                                </p>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-40">
                        <button
                          type="submit"
                          className="btn btn-block"
                          style={{ background: "#179EE7", color: "white" }}
                          onClick={handleSubmitPercent}
                        >
                          Submit Your Details for further Enquiry
                        </button>
                      </div>
                    </div>

                    <div aria-hidden={selectedLabel !== "Flat" ? true : false}>
                      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="pricing_table">
                          <div className="package_title">
                            <div
                              className="pricing-top"
                              style={{ background: "#4E4FEB", color: "#FFF" }}
                            >
                              Onecloud Services Flat Fee
                            </div>
                          </div>

                          <div
                            className="package_body"
                            style={{ background: "#EEEEEE" }}
                          >
                            <div className="price_feature">
                              <span
                                style={{
                                  color: "#4E4FEB",
                                  fontSize: 16,
                                  fontWeight: "600",
                                  textDecorationLine: "underline",
                                }}
                              >
                                Role
                              </span>
                              <span>
                                <p>
                                  <FaUserShield />
                                  &nbsp; {formData?.role}
                                </p>
                              </span>
                              <span
                                style={{
                                  color: "#4E4FEB",
                                  fontSize: 16,
                                  fontWeight: "600",
                                  textDecorationLine: "underline",
                                }}
                              >
                                Annual Salary
                              </span>
                              <span>
                                <p>
                                  <FaEuroSign />
                                  &nbsp; {formData?.salary}
                                </p>
                              </span>

                              <span
                                style={{
                                  color: "#4E4FEB",
                                  fontSize: 16,
                                  fontWeight: "600",
                                  textDecorationLine: "underline",
                                }}
                              >
                                Annual Expense
                              </span>
                              <span>
                                <p>
                                  <FaEuroSign />
                                  &nbsp; {flat}
                                </p>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-40">
                        <button
                          type="submit"
                          className="btn btn-block"
                          style={{ background: "#FF6666", color: "white" }}
                          onClick={handleSubmitFlat}
                        >
                          Submit Your Details for further Enquiry
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* </Typography> */}
                </Box>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
