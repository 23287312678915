import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as jobseeker_services from "../utils/jobseeker";

const UploadCv = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [pdf, setpdf] = useState("");
  const [cover, setcover] = useState("");
  const [jobId, setjobId] = useState("");

  useEffect(() => {
    let jobId = location?.state ? location.state.jobId : "";
    setjobId(jobId);
    console.log(jobId)
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("filename", pdf);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("cover", cover);
    formData.append("jobId", jobId);

    jobseeker_services
      .applyJobs(formData)
      .then((res) => {
        alert(res.data);
        navigate("/JobList");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div
        style={{ background: "#FFF" }}
        data-aos="fade"
        data-aos-duration="200"
      >
        <div className="container text-center ptb ptb-30">
          {/* Title Start */}
          <div className="row pb-30 text-center">
            <div className="col-sm-12 mb-20">
              <h2>
                <span>Upload</span> Your Resume
              </h2>
              <span className="b-line" />
            </div>
          </div>
          {/* Title End */}
          <form
            className="form-horizontal"
            onSubmit={handleSubmit}
            encType="multipart/form-data"
          >
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <input
                  required
                  type="text"
                  className="form-control"
                  placeholder="Plaese Enter Your Name"
                  style={{
                    border: "1px solid #3848a2",
                    height: 40,
                    borderRadius: 6,
                  }}
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <input
                  required
                  type="email"
                  className="form-control"
                  placeholder="Please Enter Your Email"
                  style={{
                    border: "1px solid #3848a2",
                    height: 40,
                    borderRadius: 6,
                  }}
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <input
                  required
                  type="number"
                  className="form-control"
                  placeholder="Please Enter Your Mobile No"
                  style={{
                    border: "1px solid #3848a2",
                    height: 40,
                    borderRadius: 6,
                  }}
                  value={phone}
                  onChange={(e) => setphone(e.target.value)}
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <input
                  required
                  type="file"
                  className="form-control"
                  style={{
                    background: "#3f51b5",
                    color: "#FFF",
                    padding: 10,
                    height: 40,
                    borderRadius: 6,
                  }}
                  accept="application/pdf"
                  onChange={(e) => setpdf(e.target.files[0])}
                />
              </div>
              <div className="col-md-12 col-sm-12">
                <textarea
                  className="form-control"
                  placeholder="Coverletter"
                  style={{
                    border: "1px solid #3848a2",
                    height: 60,
                    borderRadius: 6,
                  }}
                  value={cover}
                  onChange={(e) => setcover(e.target.value)}
                />
              </div>
              <div className="col-sm-12">
                <button
                  className="btn btn-block"
                  style={{ background: "#3f51b5", color: "#FFF", padding: 8 }}
                >
                  Apply Now
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UploadCv;
