import React, { Component } from "react";

export default class OurCompany extends Component {
  render() {
    return (
      <>
        {/* About Us*/}
        <div className="about-section padding ptb-xs-60">
          <div className="container" data-aos="fade-up" data-aos-duration="500" data-aos-offset="100">
            <div className="row">
              <div className="col-md-8 col-sm-12">
                <div className="row">
                  <div className="col-lg-5 col-lg-push-7 col-md-6 col-md-push-6 mb-xs-30 mb-sm-30">
                    <div className="heading-block top-padding">
                      <h2>
                        <span>Welcome To </span> Onecloud Service
                      </h2>
                    </div>
                  </div>
                  <div className="col-lg-7 col-lg-pull-5 col-md-6 col-md-pull-6">
                    <figure className="containerImg"> 
                      <img alt="About-11" src="assets/images/about-11.jpg" className="img-rounded" />
                    </figure>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-5">
                    <figure className="containerImg">
                      <img alt="About-12" src="assets/images/about-12.jpg" className="img-rounded" />
                    </figure>
                  </div>
                  <div className="col-sm-7">
                    <figure className="containerImg">
                      <img alt="About-13" src="assets/images/about-13.jpg" className="img-rounded" />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="top-space" style={{ textAlign: "justify" }}>
                  <p>
                    OneCloud Services is a reputable agency specializing in
                    talent acquisition and placement services within the capital
                    market and financial industry. With an unwavering commitment
                    to excellence, OneCloud Services stands out as an invaluable
                    partner for companies seeking top-tier professionals and for
                    individuals looking to advance their careers within this
                    dynamic sector.
                  </p>
                  <p>
                    Our personalised approach, deep industry knowledge, and
                    exceptional customer service make us the ideal partner for
                    any organisation looking to build a lasting relationship and
                    achieve their business objectives. Let us help you find the
                    top talent you need to succeed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Services Section */}
      </>
    );
  }
}
