import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import {FaFacebook} from 'react-icons/fa';

export default class Contact extends Component {
  // scroll = () => {
  //   const section = document.querySelector("#contact-us");
  //   section.scrollIntoView({ behavior: "smooth", block: "start" });
  // };

  render() {
    return (
      <>
        {/* CONTENT */}
        {/* Intro Section */}
        <section
          className="inner-intro padding"
          style={{
            background: "url(assets/images/services/Contact_2000.png)",
            backgroundSize: "cover",
            left: 0,
            top: 0,
            width: "100%",
            height: "600px",
            zIndex: -1,
          }}
        >
          <div className="container">
            <div className="row title">
              <h1 style={{ color: "#FFF" }}>Contact</h1>
            </div>
          </div>
        </section>
        {/* End Intro Section */}
        {/* Contact Section */}
        <section id="contact-section" className="padding ptb-xs-60">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <h2>KEEP IN TOUCH</h2>
                <p style={{ fontSize: 14, fontWeight: "500" }}>
                  Need assistance with your job search or hiring process?
                  Contact OneCloud Services today! Our expert team is dedicated
                  to providing exceptional customer service and support.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 contact pb-60 pt-30">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 text-center">
                    <i className="fa fa-file-o icon-circle pos-s" />
                    <NavLink to="/UploadCv" className="mt-15 i-block bold">
                      UPLOAD CV
                    </NavLink>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 text-center">
                    <i className="ion-ios-search icon-circle pos-s" />
                    <NavLink to="/JobSeeker" className="mt-15 i-block bold">
                      JOB SEEKER
                    </NavLink>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 text-center">
                    <i className="ion-ios-email icon-circle pos-s" />
                    <NavLink
                      to="mailto:Construc@support.com"
                      className="mt-15 i-block bold"
                    >
                      supportl@onecloudservices.com
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            <div
              id="contact-us"
              className="row"
              style={{ boxShadow: "0 0 3px 0 #000111" }}
            >
              <div className="col-lg-8 col-md-6 col-sm-12 col-xs-12">
                <div className="headeing pb-30">
                  <h2 style={{ color: "#3f51b5" }}>Get in Touch</h2>
                  <span className="b-line l-left line-h" />
                </div>
                {/* Contact FORM */}
                <form className="contact-form " id="contact">
                  {/* IF MAIL SENT SUCCESSFULLY */}
                  <div id="success">
                    <div role="alert" className="alert alert-success">
                      <strong>Thanks</strong> for using our template. Your
                      message has been sent.
                    </div>
                  </div>
                  {/* END IF MAIL SENT SUCCESSFULLY */}
                  <div className="row" data-aos="fade-up">
                    <div className="col-sm-12">
                      <div className="col-sm-6">
                        <div className="form-field">
                          <input
                            className="input-sm form-full"
                            id="name"
                            type="text"
                            name="form-name"
                            placeholder="Your Name"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-field">
                          <input
                            className="input-sm form-full"
                            id="email"
                            type="text"
                            name="form-email"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-field">
                          <input
                            className="input-sm form-full"
                            id="sub"
                            type="text"
                            name="form-subject"
                            placeholder="Subject"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="form-field">
                          <textarea
                            className="form-full"
                            id="message"
                            name="form-message"
                            placeholder="Your Message"
                            style={{ height: 100 }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 mt-30 pb-30">
                      <button
                        className="btn-text"
                        type="button"
                        id="submit"
                        name="button"
                      >
                        Send Message
                      </button>
                    </div>
                  </div>
                </form>
                {/* END Contact FORM */}
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12 contact">
                <div className="headeing">
                  <h2>Contact Info</h2>
                  <span className="b-line l-left line-h" />
                </div>
                <div className="contact-info">
                  <ul className="info">
                    <li>
                      <div className="icon ion-ios-location" />
                      <div className="content">
                        <p>272 Bath Street, Glasgow,</p>
                        <p>Scotland G2 4ER, GB</p>
                      </div>
                    </li>
                    <li>
                      <div className="icon ion-android-call" />
                      <div className="content mt-10">
                        <p>
                          <Link to="tel:+447848111706">
                            +44 (0) 7848 111 706
                          </Link>
                        </p>
                        {/* <p>200 256 265 000</p> */}
                      </div>
                    </li>
                    <li>
                      <div className="icon ion-ios-email" />
                      <div className="content mt-10">
                        <p>
                          {" "}
                          <Link to="mailto:info@onecloudservices.co.uk">
                            info@onecloudservices.co.uk
                          </Link>
                        </p>
                        {/* <p>http://admin@.com</p> */}
                      </div>
                    </li>
                  </ul>

                  <ul className="event-social">
                    <li>
                      <NavLink
                        to="https://www.facebook.com/OneCloudServices"
                        target="_new"
                      >
                        <i className="fa fa-facebook"  />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="https://www.linkedin.com/company/onecloud-services/"
                        target="_new"
                      >
                        <i className="fa fa-linkedin" />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="https://www.instagram.com/weareonecloudservices/"
                        target="_new"
                      >
                        <i className="fa fa-instagram" />
                      </NavLink>
                    </li>
                    {/* <li>
                      <a href="#">
                        <i className="fa fa-google-plus" aria-hidden="true" />
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* Map Section */}
        </section>

        {/* Map */}
        <section
          className="map-box"
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-offset="100"
        >
          <div className="map">
            <iframe
              title="OneCloud Services"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2238.8412320860166!2d-4.2713389237068045!3d55.865420983311935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x488844288cffd2ef%3A0xf5af7da822616b44!2sBlue%20Square%20Offices!5e0!3m2!1sen!2snp!4v1684575750756!5m2!1sen!2snp"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen={true}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </section>
        {/* Contact Section */}
        {/*End Contact*/}
      </>
    );
  }
}
