import React, { Component } from "react";
import * as job_service from "../utils/JobServices";
import { Link, NavLink } from "react-router-dom";
import {
  FaDirections,
  FaHourglassStart,
  FaBuffer,
  FaArrowRight,
  FaHeart,
  FaTachometerAlt,
  FaBusinessTime,
  FaRegBuilding,
  FaEuroSign,
} from "react-icons/fa";

export default class BlogSection extends Component {
  state = {
    JobList: [],
    jobTypeList: [],
    jobType: [],
    jobTypeId: "",
  };

  componentDidMount() {
    this.getJobList();
    this.getJobtypeList();
  }

  getJobList = () => {
    job_service
      .jobList()
      .then((res) => {
        this.setState({
          JobList: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getJobtypeList = () => {
    job_service
      .jobTypeList()
      .then((res) => {
        this.setState({
          jobTypeList: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const regex = /(<([^>]+)>)/gi;
    return (
      <>
        {/*blog start*/}
        <section className="mt-30">
          <div
            className="container"
            data-aos="fade-down"
            data-aos-duration="500"
            data-aos-offset="100"
          >
            {/* Title Start */}
            <div className="row pb-30 text-center">
              <div className="col-sm-12 mb-20">
                <h2>
                  <span style={{ color: "#0f64db" }}>Latest</span> Job Listing
                </h2>
                <span className="b-line" />
              </div>
            </div>
            {/* Title End */}

            <div className="row">
              {/* featured Job Start */}
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <div
                  style={{
                    borderBottom: "1px solid #000",
                    marginBottom: 15,
                  }}
                >
                  <h2 style={{ fontSize: 18, fontWeight: 700 }}>
                    Featured Jobs
                  </h2>
                </div>

                <div>
                  <img src="assets/images/featured.png" alt="" />
                  <sup
                    style={{
                      position: "absolute",
                      top: 45,
                      right: 15,
                      fontSize: 14,
                      border: "10px solid #0f64db",
                      color: "#FFF",
                    }}
                  >
                    FEATURED JOBS
                  </sup>
                </div>

                <div className="border">
                  <div>
                    <p
                      style={{
                        fontSize: 16,
                        padding: 8,
                        margin: 5,
                        fontWeight: 800,
                        color: "#0f64db",
                      }}
                    >
                      Frontend Web Developer
                    </p>
                  </div>

                  <div
                    style={{
                      margin: 10,
                      color: "#000",
                      fontWeight: 500,
                      fontSize: 12,
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <FaDirections size={20} style={{ marginTop: 3 }} />
                      <span style={{ marginTop: 1, marginLeft: 10 }}>
                        Glasgow G20
                      </span>
                    </div>

                    <div style={{ display: "flex" }}>
                      <FaHourglassStart size={20} style={{ marginTop: 10 }} />
                      <span style={{ marginTop: 8, marginLeft: 10 }}>
                        Hybrid remote
                      </span>
                    </div>

                    <div style={{ display: "flex" }}>
                      <FaBuffer size={20} style={{ marginTop: 10 }} />
                      <span style={{ marginTop: 8, marginLeft: 10 }}>
                        Up to <i className="fa fa-eur"></i>35,000/Yr
                      </span>
                    </div>

                    <hr />
                    <p>
                      Please click the button below to search all featured jobs
                    </p>
                    <hr />
                    <div className="pb-30">
                      <NavLink to="/JobList">
                        <button
                          className="btn btn-block"
                          style={{
                            background: "#0f64db",
                            color: "#FFF",
                            fontSize: 14,
                          }}
                        >
                          Search All Jobs
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              {/* featured Job End */}

              {/* Job Section Start */}
              <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9">
                <div
                  style={{
                    marginBottom: 15,
                    borderBottom: "1px solid #000",
                  }}
                >
                  <h2
                    className="text-right"
                    style={{ fontSize: 18, fontWeight: 700 }}
                  >
                    Recent Jobs
                  </h2>
                </div>

                <div className="row">
                  {this.state.JobList.length > 0
                    ? this.state.JobList.map((item, index) => {
                        return (
                          <div key={index}>
                            <div className="col-sm-4">
                              <div
                                id="divHover"
                                style={{
                                  padding: 10,
                                  borderRadius: 5,
                                  // border: "1px solid #0463AD",
                                  boxShadow: "1px 1px 5px 0px #ccc",
                                }}
                              >
                                {/* <div style={{ display: "flex" }} >
                                  <FaRegBuilding size={36} color="#0364CD" />
                                  <span
                                    style={{
                                      fontSize: 16,
                                      margin: 8,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Company Name
                                  </span>
                                </div> */}

                                <div>
                                  <h2
                                    style={{
                                      fontSize: 16,
                                      marginBottom: 10,
                                      marginTop: 10,
                                      fontWeight: "bold",
                                      height: 30,
                                    }}
                                  >
                                    {item.title}
                                  </h2>
                                  <p
                                    style={{
                                      fontSize: 14,
                                      marginBottom: 20,
                                    }}
                                  >
                                    {item.description
                                      .replace(regex, "")
                                      .substring(0, 50)}
                                  </p>
                                  <div
                                    style={{
                                      width: "100%",
                                      fontSize: 12,
                                      marginBottom: 10,
                                      marginTop: 10,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item.location}
                                    {/* <span
                                      style={{
                                        border: "1px solid red",
                                        borderRadius: 5,
                                        padding: 3,
                                      }}
                                    >
                                      {this.state.jobTypeList.map((jobtype) =>
                                        jobtype.typeId === item.jobTypeId
                                          ? jobtype.typeName
                                          : ""
                                      )}
                                    </span> */}
                                    {/* <span
                                      style={{
                                        marginLeft: 10,
                                        border: "1px solid blue",
                                        borderRadius: 5,
                                        padding: 3,
                                      }}
                                    >
                                      {item.location}
                                    </span> */}
                                    {/* <span
                                      style={{
                                        marginLeft: 20,
                                        border: "1px solid green",
                                        borderRadius: 5,
                                        padding: 3,
                                      }}
                                    >
                                      Remote
                                    </span> */}
                                  </div>
                                  <div
                                    style={{
                                      width: "100%",
                                      fontSize: 12,
                                      marginBottom: 10,
                                    }}
                                  >
                                    <span
                                      style={{
                                        border: "1px solid #FF5555",
                                        borderRadius: 5,
                                        padding: 3,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.state.jobTypeList.map((jobtype) =>
                                        jobtype.typeId === item.jobTypeId
                                          ? jobtype.typeName
                                          : ""
                                      )}
                                    </span>

                                    <span
                                      style={{
                                        marginLeft: 20,
                                        border: "1px solid green",
                                        borderRadius: 5,
                                        padding: 3,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <FaEuroSign />
                                      {item.salary}/<sup>Annually</sup>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <Link to={`/JobDetails/${item.jobId}`}>
                                    <button
                                      className="btn btn-block"
                                      style={{
                                        background: "#043FAD",
                                        color: "#FFF",
                                        borderRadius: 2,
                                      }}
                                    >
                                      View Detail
                                    </button>
                                    </Link>

                                    {/* <button
                                      style={{
                                        background: "#FF6666",
                                        color: "#FFF",
                                        borderRadius: 3,
                                      }}
                                    >
                                     <FaHeart  /> Save Job
                                    </button> */}
                                  </div>
                                </div>
                              </div>
                              <br />
                            </div>
                          </div>
                        );
                      })
                    : "default"}
                </div>
              </div>
              {/* Job Section End */}
            </div>
          </div>
        </section>
        {/* <hr style={{border:'2px solid #CCC'}}/> */}
        {/*blog end*/}
      </>
    );
  }
}

// https://www.facebook.com/reel/149794507748548
