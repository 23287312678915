import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import Logo from "../assets/MainCloud.png";
import calculator from "../assets/calculator.jpg"

export default class FooterSection extends Component {
  topFunction = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <>
        <div className="sticky-container">
          <ul className="sticky-button">
            <li>
              {/* <i className="fa fa-2x fa-calculator" ></i> */}
              <img src={calculator}  className="blink" />
              <p>
                <NavLink to="/CostCalculator" target="_blank">
                  Compare 
                  <br />
                  Your Cost
                </NavLink>
              </p>
            </li>
          </ul>
        </div>

        {/* <NavLink to="/CostCalculator" id="cost_calculator">
          <strong title="Cost Calculator">
            <i className="fa fa-2x fa-calculator"></i>
            <p style={{ fontSize: 12 }}>Compare Your Cost</p>
          </strong>
        </NavLink> */}

        <button
          onClick={this.topFunction}
          id="myBtn"
          title="Go to top"
          style={{ height: 30, width: 30 }}
        >
          <i className="fa fa-arrow-up"></i>
        </button>
        {/*Footer Section Start*/}
        <footer data-aos="fade" data-aos-duration="500">
          <div className="top_footer_info__block ptb-20">
            <div className="container">
              <div className="row">
                <div className="col-sm-4">
                  <div className="single_info__block">
                    <i className="fa fa-phone" />
                    <h4>
                      <NavLink
                        to="tel:+4407848111706"
                        style={{ color: "white" }}
                      >
                        +44 (0) 7848 111 706 <span>Monday-Friday, 9am-5pm</span>
                      </NavLink>
                    </h4>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="single_info__block">
                    <i className="fa fa-envelope-o" />
                    <h4>
                      <NavLink
                        to="mailto:+info@onecloudservices.com"
                        style={{ color: "white" }}
                      >
                        info@onecloudservices.co.uk
                        <span>Monday-Friday, 9am-5pm</span>
                      </NavLink>
                    </h4>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="single_info__block">
                    <i className="fa fa-bullhorn" />
                    <h4>
                      <NavLink to="/Training" style={{ color: "white" }}>
                        Request for Training
                        <span>
                          Build Your Dreams and Let's Now accomplish it.
                        </span>
                      </NavLink>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="main_footer__block pb-0 pt-60">
            <div className="container">
              <div className="row">
                <div className="col-md-3">
                  <div className="footer_box__block">
                    <h3>About Us</h3>
                    <p>
                      our size is our strength. We’re small enough to care but
                      big enough to make an impact.
                    </p>
                    <p>
                      Our actions and decisions are guided by teamwork, respect,
                      integrity, determination, understanding, and fun.
                      <NavLink to="/AboutUs" style={{ color: "#FFF" }}>
                        {" "}
                        Read More...
                      </NavLink>
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="footer_box__block">
                    <h3>Our Services</h3>
                    <ul>
                      <li>
                        <NavLink to="Contracting">Contracting</NavLink>
                      </li>
                      <li>
                        <NavLink to="Permanent">Permanent</NavLink>
                      </li>
                      <li>
                        <NavLink to="Temporary">
                          Temporary Agency Staffing
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="Overseas">Overseas Recruitment</NavLink>
                      </li>
                      <li>
                        <NavLink to="HomeCare">HomeCare</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="footer_box__block">
                    <h3>Our Industries</h3>
                    <ul>
                      <li>
                        <NavLink to="HealthCare">Health Care</NavLink>
                      </li>
                      <li>
                        <NavLink to="Financial">Financial</NavLink>
                      </li>
                      <li>
                        <NavLink to="Banking">Banking</NavLink>
                      </li>
                      <li>
                        <NavLink to="CapitalMarket">Capital Market</NavLink>
                      </li>
                      <li>
                        <NavLink to="InformationTechnology">
                          Information Technology
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="footer_box__block address-box">
                    <h4>Contact info</h4>
                    <ul>
                      <li>
                        <i className="fa fa-phone" />
                        <p>
                          <Link to="tel:+447848111706">
                            Call Us +44 (0) 7848 111 706
                          </Link>
                        </p>
                      </li>
                      <li>
                        <i className="fa fa-envelope-o" />
                        <p>
                          <Link to="mailto:info@onecloudservices.co.uk">
                            info@onecloudservices.co.uk
                          </Link>
                        </p>
                      </li>
                      <li>
                        <i className="fa fa-map-marker" />
                        <p>272 Bath Street, Glasgow, Scotland G2 4ER, GB</p>
                      </li>
                      <li>
                        <i className="fa fa-clock-o" />
                        <p>
                          Monday-Firday, 9am-5pm <br /> Sunday Closed
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="copyriight_block ptb-20">
                <div className="row">
                  <div className="col-sm-6">
                    <NavLink to="/" className="footer__block-logo">
                      <img
                        src={Logo}
                        alt="OneCloud Services"
                        style={{ width: 100 }}
                      />
                    </NavLink>
                  </div>
                  <div className="col-sm-6 text-right">
                    <p>All Rights Reserved &copy; Onecloud Services</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/*Footer Section End*/}
      </>
    );
  }
}
