import React, { useEffect, useState } from "react";
import * as job_service from "../utils/JobServices";
import { Link, useParams, useNavigate, Navigate } from "react-router-dom";
import {
  FaJoomla,
  FaEuroSign,
  FaInfo,
  FaQuora,
  FaRegistered,
  FaDochub,
  FaTag,
  FaCentos,
  FaCalendarAlt,
  FaCheck,
} from "react-icons/fa";
import { BiLocationPlus } from "react-icons/bi";

export default function JobDetails() {
  const navigate = useNavigate();
  const regex = /(<([^>]+)>)/gi;
  const { JobId } = useParams();
  console.log(JobId);
  const [data, setData] = useState({});
  const [JobTypeList, setJobTypeList] = useState([]);
  const [JobCategoryList, setJobCategoryList] = useState([]);
  const [JobSkillList, setJobSkillList] = useState([]);

  const [JobList, setJobList] = useState([]);

  useEffect(() => {
    getJobList();
    getData(JobId);
    getJobCategoryList();
    getJobtypeList();
    getJobSkillList();
  }, [JobId]);

  const getJobList = () => {
    job_service
      .jobList()
      .then((res) => {
        setJobList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = (JobId) => {
    job_service
      .jobDetails(JobId)
      .then((res) => {
        setData(res.data);
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getJobtypeList = () => {
    job_service
      .jobTypeList()
      .then((res) => {
        setJobTypeList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getJobCategoryList = () => {
    job_service
      .jobCategoryList()
      .then((res) => {
        setJobCategoryList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getJobSkillList = () => {
    job_service
      .jobSkillList()
      .then((res) => {
        setJobSkillList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <section
        style={{
          height: "200px",
          background: "url(assets/images/hero-bg.jpg)",
          color: "white",
        }}
      >
        <div className="container">
          <div className="mt-20">
            <h3>{data.title}</h3>
            <h4>
              <BiLocationPlus size={14} />
              &nbsp;{data.location}
            </h4>
            <h4>
              <FaEuroSign size={14} />
              &nbsp;{data.salary}/ <sup>Annual</sup>
            </h4>
            <h4>
              <FaJoomla size={14} />
              &nbsp;
              {JobTypeList.map((jobtype) =>
                jobtype.typeId === data.jobTypeId ? jobtype.typeName : ""
              )}
            </h4>

            <button
              onClick={() => navigate("/UploadCv", { state: { jobId: JobId } })}
              className="btn btn-md btn-rounded"
              style={{ background: "#0F64DB", width: 200, color: "#FFF" }}
            >
              Apply Now
            </button>
          </div>
        </div>
      </section>

      <div className="ptb-40 ptb-xs-40">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12">
              <h3>
                <FaDochub />
                escription
              </h3>
              <div style={{ textAlign: "justify" }}>
                &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                {data.description ? data.description.replace(regex, " ") : ""}
              </div>

              <h3 className="mt-40">
                <FaQuora />
                ualification
              </h3>
              <div style={{ textAlign: "justify" }}>
                &nbsp; &nbsp; &nbsp; &nbsp; {data.qualification}
              </div>

              <h3 className="mt-40">
                <FaRegistered />
                esponsibility
              </h3>
              <div style={{ textAlign: "justify" }}>
                &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                {data.responsibility
                  ? data.responsibility.replace(regex, " ")
                  : ""}
              </div>
            </div>

            <div
              className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-xs-12"
              style={{
                height: "500px",
                borderRadius: 10,
                border: "1px dashed #000",
              }}
            >
              <div className="col-sm-12">
                <h3>Job Category</h3>
                <span>
                  <FaCentos /> &nbsp;
                  {JobCategoryList.map((JobCategory) =>
                    JobCategory.categoryId === data.categoryId
                      ? JobCategory.categoryName
                      : ""
                  )}
                </span>
              </div>
              <div className="col-sm-12 mt-20 ">
                <h3>Skill/Tags</h3>
                <span>
                  <FaTag /> &nbsp;
                  {JobSkillList.map((JobSkill) =>
                    JobSkill.skillId === data.skillId ? JobSkill.skillName : ""
                  )}
                </span>
              </div>
              <div className="col-sm-12 mt-20 ">
                <h3>Publish Date</h3> <FaCalendarAlt />
                &nbsp; {data.publishDate}
              </div>
              <div className="col-sm-12 mt-20">
                <h3>Deadline Date</h3>
                <FaCalendarAlt />
                &nbsp; {data.deadline}
              </div>
              <div className="col-sm-12 mt-20">
                <h3>Job Status</h3>
                <FaCheck />
                {data.jobStatus === "1" ? " Active" : "InActive"}
              </div>
            </div>
          </div>
          {/* end of row */}
          <hr />
          <div className="row mt-30">
            <div className="col-sm-12">
              <span style={{ fontSize: 20, fontWeight: "bold" }}>
                Similar Jobs
              </span>
              <Link
                className="btn btn-sm btn-rounded pull-right"
                to={"/JobList"}
                style={{ background: "#0F64DB", color: "#FFF" }}
              >
                VIEW ALL JOBS
              </Link>
              <hr />
            </div>

            {JobList.length > 0
              ? JobList.map((item, index) => {
                  return (
                    <div  key={index} className="col-sm-3" style={{marginBottom:10}}>
                      <div id="divHover" style={{boxShadow:'1px 1px 4px 2px #CCC',padding:10, borderRadius:5}}>
                        <Link to={`/JobDetails/${item.jobId}`}>
                          <h2
                            style={{
                              marginTop: 15,
                              fontSize: 18,
                              fontWeight: 600,
                              height: "35px",
                            }}
                          >
                            {item.title}
                          </h2>
                        </Link>

                        <p>{item.location}</p>

                        <Link to={`/JobDetails/${item.jobId}`} target="_new">
                          <button
                            className="btn btn-block"
                            style={{
                              marginBottom: 5,
                              background: "#0f64db",
                              color: "#FFF",
                              borderRadius: 3,
                            }}
                          >
                            VIEW JOB DETAIL
                          </button>
                        </Link>
                      </div>
                    </div>
                  );
                })
              : "default"}
          </div>
        </div>
      </div>
    </>
  );
}
