import { api_instance } from "./api";



//register
export const register = (payload) => {
  //role2
  return api_instance.post("/register.php", payload);
};


//login
export const login = (payload) => {
  return api_instance.post("/login.php", payload);
};

//logout
export const logout = () => {
  return api_instance.post(`/logout.php`);
};

