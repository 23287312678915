import React, { Component } from "react";
import * as job_service from "../utils/JobServices";
import { Link, useLocation } from "react-router-dom";
import { FaAsterisk, FaHourglassStart, FaEuroSign } from "react-icons/fa";
import { BiLocationPlus } from "react-icons/bi";
import { TextField } from "@mui/material";

import JobCardComponent from "../components/JobCardComponent";

function withParams(Component) {
  return (props) => <Component {...props} location={useLocation()} />;
}

class JobList extends Component {
  state = {
    JobList: [],
    jobTypeList: [],
    jobType: [],
    jobTypeId: "",
    searchTitle: "",
    JobTitle: [],
    query: "",
    skillId: "",
    skillData: [],
    locationQuery: "",
    locationData: [],
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.getJobList();
    this.getJobtypeList();
    if (this.state.query) {
      this.setState({
        searchTitle: this.props.location.state.query,
        query: this.props.location.state.query,
        locationQuery: this.props.location.state.location,
      });
      console.log(this.state.query);
    }
  }

  // Skill Search Start

  handleInputChange = (event) => {
    let JobSkillName = event.target.value;
    this.setState({
      query: JobSkillName,
    });
    job_service
      .jobSkillList()
      .then((res) => {
        const results = res.data.filter((element) => {
          return (
            JobSkillName &&
            element &&
            element.skillName &&
            element.skillName.toLowerCase().includes(JobSkillName)
          );
        });
        console.log(results);
        this.setState({
          skillData: results,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getSkillDataById = (skillName) => {
    job_service
      .jobSkillByName(skillName)
      .then((res) => {
        this.getJobBySkillId(res.data);
        this.setState({
          skillId: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  selectSkill = (skillName) => {
    this.getSkillDataById(skillName);
    this.setState({
      query: skillName,
      skillData: [],
    });
  };

  getJobBySkillId = (skillId) => {
    job_service
      .jobListBySkill(skillId)
      .then((res) => {
        this.setState({
          JobList: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Skill Search End

  // handle Title

  handleTitle = (event) => {
    const title = event.target.value;
    this.getJobTitle(title);
    this.setState({
      searchTitle: title,
    });
  };

  getJobTitle = (title) => {
    job_service
      .jobTitleList(title)
      .then((res) => {
        const results = res.data.filter((element) => {
          return (
            title &&
            element &&
            element.title &&
            element.title.toLowerCase().includes(title)
          );
        });
        this.setState({
          JobTitle: results,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  selectTitle = (title) => {
    this.setState({
      searchTitle: title,
      JobTitle: [],
    });

    this.getJobByTitle(title);
  };

  getJobByTitle = (title) => {
    if (title) {
      job_service
        .jobListByTitle(title)
        .then((res) => {
          this.setState({
            JobList: res.data,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // end of title

  handleTitleFilter = () => {
    this.getJobList();
    this.setState({
      searchTitle: "",
    });
  };

  getJobList = () => {
    job_service
      .jobList()
      .then((res) => {
        this.setState({
          JobList: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getJobtypeList = () => {
    job_service
      .jobTypeList()
      .then((res) => {
        this.setState({
          jobTypeList: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (event) => {
    let selected = event.target.value;
    //alert(selected);
    this.setState({
      jobTypeId: selected,
    });
    this.handleJobTypeSelect(selected);
  };

  handleJobTypeSelect = (JobTypeId) => {
    job_service
      .jobDetailsByJobTypeId(JobTypeId)
      .then((res) => {
        this.setState({
          JobList: res.data,
        });
      })
      .catch((err) => {
        // console.log(err);
        this.setState({
          JobList: [],
        });
      });
  };

  // Location Search Start

  handleLocationChange = (value) => {
    this.setState({
      locationQuery: value,
    });
    this.getLocationData(value);
  };

  getLocationData = (value) => {
    job_service.jobLocationList().then((res) => {
      const results = res.data.filter((element) => {
        return (
          value &&
          element &&
          element.locationName &&
          element.locationName.toLowerCase().includes(value)
        );
      });
      this.setState({
        locationData: results,
      });
    });
  };

  selectLocation = (locationName) => {
    this.setState({
      locationQuery: locationName,
      locationData: [],
    });
  };

  render() {
    const regex = /(<([^>]+)>)/gi;
    return (
      <>
        <section
          style={{ height: "80px", background: "#0F64DB", color: "white" }}
        >
          <div className="container">
            <div className="row mt-20">
              <h3 data-title="Featured Job Listing">
                <span>Featured Job Listing</span>
              </h3>
            </div>
          </div>
        </section>
        <div className="ptb-60 ptb-xs-60">
          <div className="container">
            <div className="row pb-30">
              <div
                className="col-xxl-3 col-xl-3 col-lg-3 col-md-3"
                style={{ border: "1px solid #000" }}
              >
                <div className="mt-10">
                  <h3>Filter Results By</h3>
                  <hr />

                  <div className="col-sm-12">
                    <div className="control-group">
                      <label>Job Title</label>
                      <TextField
                        type="text"
                        variant="filled"
                        placeholder="Keywords-React/Java"
                        style={{ width: "100%" }}
                        name={this.state.searchTitle}
                        value={this.state.searchTitle}
                        onChange={this.handleTitle}
                      />

                      <div className="results-list">
                        {this.state.JobTitle.map((i, index) => (
                          <div
                            key={index}
                            className="search-result"
                            onClick={(e) => this.selectTitle(i.title)}
                          >
                            {i.title}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 mt-10">
                    <div className="control-group">
                      <label>Skill Set</label>
                      <TextField
                        style={{ width: "100%" }}
                        type="text"
                        placeholder="Eg:PHP/React Native/Java"
                        id="filled-basic"
                        variant="filled"
                        name={this.state.query}
                        onChange={this.handleInputChange}
                        value={this.state.query}
                      />

                      <div className="results-list">
                        {this.state.skillData.map((i, index) => (
                          <div
                            key={index}
                            className="search-result"
                            onClick={(e) => this.selectSkill(i.skillName)}
                          >
                            {i.skillName}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="control-group">
                      <label>Select Job type</label>

                      <select
                        data-live-search="true"
                        className="form-control selectpicker"
                        value={this.state.jobTypeId}
                        label="Please Select Job Type"
                        onChange={this.handleChange}
                      >
                        <option value="">Please Select Job type</option>
                        {this.state.jobTypeList.map((option) => (
                          <option
                            key={option.typeId}
                            value={option.typeId}
                            // onChange={(e) =>
                            //   this.handleJobTypeSelect(option.typeId)
                            // }
                          >
                            {option.typeName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="control-group">
                      <label>Job Location</label>
                      <TextField
                        style={{ width: "100%" }}
                        type="text"
                        placeholder="Eg:Remote/Scotland/Nj"
                        id="filled-basic"
                        variant="filled"
                        name="txtCompanyName"
                        onChange={(e) =>
                          this.handleLocationChange(e.target.value)
                        }
                        value={this.state.locationQuery}
                      />

                      <div className="results-list">
                        {this.state.locationData.map((i, index) => (
                          <div
                            key={index}
                            className="search-result"
                            onClick={(e) => this.selectLocation(i.locationName)}
                          >
                            {i.locationName}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 mt-10 ptb-40">
                    <button
                      type="submit"
                      className="btn btn-sm btn-block"
                      style={{ background: "#0364AD", color: "#FFF" }}
                    >
                      SEARCH JOB
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9">
                <div className="col-sm-12">
                  <div
                    className="pull-left"
                    style={{
                      color: "#CD1818",
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    Job List
                  </div>

                  {this.state.searchTitle.length > 0 ? (
                    <div className="pull-right">
                      <span onClick={this.handleTitleFilter}>
                        {this.state.searchTitle} <FaAsterisk />
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {this.state.JobList && this.state.JobList.length > 0 ? (
                  this.state.JobList.map((item, index) => {
                    return (
                      <div key={index} className="col-sm-12">
                        <Link to={`/JobDetails/${item.jobId}`}>
                          <JobCardComponent
                            jobTitle={item.title}
                            // company="ExampleTech"
                            location={item.location}
                            jobType={this.state.jobTypeList.map((jobtype) =>
                              jobtype.typeId === item.jobTypeId
                                ? jobtype.typeName
                                : ""
                            )}
                            experienceLevel={item.salary}
                            description={
                              item.description
                                .replace(regex, "")
                                .substring(0, 50) + "......."
                            }
                          />
                        </Link>
                      </div>
                    );
                  })
                ) : (
                  <div>No Jobs Found</div>
                )}
              </div>
              {/* End of col-9 */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withParams(JobList);
