import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class JobSeeker extends Component {
  render() {
    return (
      <>
        <section
         data-aos="fade"
          style={{
            background: "url(assets/images/services/db.jpg)",
            left: 0,
            top: 0,
            width: "100%",
          }}
        >
          {/* <div className="container">
            <div className="row title">
              <h3 style={{ padding: 5, marginTop: 40 }}>
                Search Resume Database & Download Now!
              </h3>
              <div className="page-breadcrumb">
                Save Time and Effort • Hire the Best Candidates • Find the
                Accurate Talent Faster • Hire Candidates Instantly • 100% Hiring
                Support
              </div>
            </div>
          </div>
          <hr /> */}

          <div className="container ptb">
            <div className="light-color">
              
              <div className="row">
                <div
                 data-aos="fade" 
                  className="col-sm-5"
                  style={{
                    background: "#FFF",
                    padding: 10,
                    bottom: 10,
                    borderRadius: 10,
                    boxShadow: "1px 2px 3px 2px #000",
                  }}
                >
                  <h3 style={{ bottom: 5, color: "#000" }}>
                    <strong style={{ color: "#3F51B5" }}>
                      {" "}
                      Start Recruiting Right{" "}
                    </strong>
                    & Talent Candidates Today!
                  </h3>
                  <hr />
                  <form className="clearfix form-inline">
                    <div className="form-field-wrapper form-full">
                      <input
                        type="text"
                        id="name"
                        className="input-sm form-full"
                        placeholder="Enter Company Or Organization Name"
                        style={{
                          borderRadius: 3,
                          borderWidth: 1,
                          borderColor: "black",
                        }}
                      />
                    </div>

                    <div className="form-field-wrapper form-full">
                      <input
                        type="text"
                        id="mobile"
                        maxLength={10}
                        className="input-sm form-full"
                        placeholder="Enter Mobile Number..."
                        style={{
                          borderRadius: 3,
                          borderWidth: 1,
                          borderColor: "black",
                        }}
                      />
                    </div>
                    <div className="form-field-wrapper form-full">
                      <input
                        type="email"
                        id="email"
                        className="input-sm form-full"
                        placeholder="Enter Company Email"
                        style={{
                          borderRadius: 3,
                          borderWidth: 1,
                          borderColor: "black",
                        }}
                      />
                    </div>
                    <div className="form-field-wrapper form-full">
                      <input
                        type="text"
                        id="Name"
                        className="input-sm form-full"
                        placeholder="Enter Your Name"
                        style={{
                          borderRadius: 3,
                          borderWidth: 1,
                          borderColor: "black",
                        }}
                      />
                    </div>

                    <div className="form-field-wrapper ">
                      <button
                        name="submit"
                        className="btn btn-block"
                        style={{
                          borderRadius: 3,
                          fontSize: 15,
                          background: "#3F51B5",
                        }}
                      >
                        <i className="fa fa-search"></i> HIRE NOW
                      </button>
                    </div>
                  </form>
                </div>

                <div className="col-sm-offset-1 col-sm-6"  data-aos="fade-right" data-aos-duration="100">
                  <h3 style={{ color: "#FEFF86" }}>
                    Search Resume Database & Download Now!
                  </h3>
                  <hr/>
                  <div style={{ marginTop: 10 }}>
                    <p
                      style={{ color: "#FFF", fontSize: 20, fontWeight: "600" }}
                    >
                      {" "}
                      - Save Time and Effort{" "}
                    </p>
                    <p
                      style={{ color: "#FFF", fontSize: 20, fontWeight: "600" }}
                    >
                      {" "}
                      - Hire the Best Candidates
                    </p>
                    <p
                      style={{ color: "#FFF", fontSize: 20, fontWeight: "600" }}
                    >
                      {" "}
                      - Find the Accurate Talent Faster
                    </p>
                    <p
                      style={{ color: "#FFF", fontSize: 20, fontWeight: "600" }}
                    >
                      {" "}
                      - Hire Candidates Instantly
                    </p>
                    <p
                      style={{ color: "#FFF", fontSize: 20, fontWeight: "600" }}
                    >
                      {" "}
                      - 100% Hiring Support
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

        <section className="progress_wrap__block padding ptb-xs-60 bg-gray"  data-aos="fade-up" data-aos-duration="200" data-aos-offset="100">
          <div className="container">

            <div className="row">
              <div className="col-md-12">
                <div className="section_title__block text-center mb-30">
                  <h2>How It Works ?</h2>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12 contact pb-60 pt-30">
                <div className="row text-center bold">
                  <div className="col-sm-3 pb-xs-30">
                    <i className="fa fa-database icon-circle pos-s" />
                    <p className="mt-15 i-block">Subscribe resume database</p>
                  </div>

                  <div className="col-sm-3 pb-xs-30">
                    <i className="fa fa-search-plus icon-circle pos-s" />
                    <p className="mt-15 i-block">Search suitable candidates</p>
                  </div>

                  <div className="col-sm-3 pb-xs-30">
                    <i className="fa fa-sort-alpha-desc icon-circle pos-s"></i>
                    <p className="mt-15 i-block">
                      Source and contact the candidates
                    </p>
                  </div>

                  <div className="col-sm-3 pb-xs-30">
                    <i className="fa fa-signal icon-circle pos-s"></i>
                    <p className="mt-15 i-block">Hire and Build Your Network</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
