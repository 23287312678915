import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import HeaderMenu from "../components/HeaderMenu";
import FooterSection from "../components/FooterSection";
import ScrollToTop from "../components/ScrollToTop";

class MainPage extends Component {
  render() {
    return (
      <>
        <ScrollToTop>
          <HeaderMenu />
          <Outlet />
          <FooterSection />
        </ScrollToTop>
      </>
    );
  }
}

export default MainPage;
