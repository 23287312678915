import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export default class Contracting extends Component {
  render() {
    return (
      <>
        {/* Intro Section */}
        <section
         data-aos="fade" data-aos-duration="800"
          className="inner-intro"
          style={{
            background:
              "url(assets/images/services/Contract_2000.png)",
            backgroundSize: "cover",
            left: 0,
            top: 0,
            width: "100%",
            height:'600px',
            zIndex: -1,
          }}
        >
          <div className="container"  data-aos="fade-up" data-aos-duration="800" >
            <div className="row title">
              <h1 data-title="Leadership">
                <span style={{color:'#CD1818'}}>Contracting Service</span>
              </h1>
            </div>
          </div>
        </section>
        {/* Intro Section */}
        <section className="padding ptb-xs-60">
          <div className="container">
            <div className="row">

              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-xs-40 pb-xs-40"  data-aos="fade-up" data-aos-duration="800" data-aos-offset="100">
                <div className="tab-title">
                  
                  <ul className="clearfix">
                    <li>
                      <NavLink to={"/Service"}>Our Services</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Contracting"} className="active">
                        Contracting Services
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Permanent"}>Permanent Services</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Temporary"}>
                        Temporary Agency Staffing
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/HomeCare"}>HomeCare Services</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Overseas"}>Overseas Recruitment</NavLink>
                    </li>
                    <li>
                      <NavLink to={"/Training"}>Traning</NavLink>
                    </li>

                    
                  </ul>
                </div>
              </div>

              <div className="col-lg-8 col-md-8 col-sm-8"  data-aos="fade-down" data-aos-duration="800" data-aos-offset="100">
                <div className="tab-content">
                  <div className="row">
                    <div className="col-sm-12" style={{ top: -15 }}>
                      <img
                        src="assets/images/services/Contract_500.png"
                        alt="Training & Consultation Services"
                      />
                    </div>
                    <div className="col-sm-12">
                      <h2 className="pt-30">Contracting Service</h2>
                      <p className="text-justify text-content">
                        Contract recruitment refers to the practice of hiring
                        individuals on a temporary or contingent basis to
                        fulfill specific roles or projects within an
                        organization. It is a flexible staffing solution that
                        allows companies to augment their workforce quickly and
                        efficiently without committing to permanent employment.
                      </p>
                      <p>
                        In contract recruitment, the employment relationship
                        between the company and the individual is typically for
                        a fixed duration or until the completion of a specific
                        project. The contract can range from a few weeks to
                        several months or even longer, depending on the nature
                        of the assignment.
                      </p>
                      <p>
                        Contractors, also known as contingent workers or
                        freelancers, are engaged for their specialized skills
                        and expertise to address specific business needs. They
                        are often hired for roles that require immediate
                        support, specialized knowledge, or to manage
                        fluctuations in workload or project-based work.
                      </p>
                      <p className="text-justify text-content"  data-aos="fade-up" data-aos-duration="600" data-aos-offset="110">
                        We understand that you need to balance accessing niche
                        or specific skill sets with the ability to scale your
                        workforce as needed. That's why we offer exclusive
                        knowledge and insights to help you define your temporary
                        talent strategy. Although every situation is unique,
                        we've helped businesses overcome similar challenges,
                        both in your industry and outside. We've built up a deep
                        knowledge base with insights that can be applied to your
                        specific needs. We are committed to working with you as
                        your lifelong partner to find temporary candidates who
                        can provide the niche expertise and new perspective you
                        need. Whether you're searching for just one skilled
                        specialist or looking to build an entire team, we're
                        with you every step of the way.
                      </p>
                      <p className="text-justify"  data-aos="fade-down" data-aos-duration="700" data-aos-offset="110">
                        <span className="bold">
                          Companies opt for contract recruitment for several
                          reasons:
                        </span>
                        <ol>
                          <li>
                            <span className="bold">Flexibility</span>: Contract
                            recruitment allows companies to scale their
                            workforce up or down based on changing business
                            demands. It provides the flexibility to quickly
                            bring in additional resources during peak periods or
                            to meet short-term project requirements.
                          </li>
                          <li>
                            <span className="bold">Specialized Skills</span>:
                            Contract recruitment enables organizations to access
                            a pool of highly skilled professionals with
                            specialized expertise. These contractors often
                            possess niche skills or experience that may not be
                            available within the company&#39;s existing
                            workforce.
                          </li>
                          <li>
                            <span className="bold">Cost Control</span>: Hiring
                            contractors can be a cost-effective solution
                            compared to hiring permanent employees, as companies
                            are not responsible for providing benefits such as
                            healthcare, retirement plans, or paid time off.
                            Contract workers are typically paid on an hourly or
                            project basis, providing more control over labor
                            costs.
                          </li>
                          <li>
                            <span className="bold">Project-Based Work</span>:
                            Contract recruitment is well-suited for
                            project-based assignments where specific skills or
                            resources are needed for a defined period.
                            Contractors can be brought in to manage and deliver
                            specific projects, ensuring efficient resource
                            allocation.
                          </li>
                          <li>
                            <span className="bold">Trial Period</span>: Contract
                            recruitment allows organizations to evaluate a
                            candidate&#39;s performance and fit within the
                            company before considering them for permanent
                            employment. It provides an opportunity to assess the
                            contractor&#39;s capabilities and cultural alignment
                            before making a long-term commitment.
                          </li>
                        </ol>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row"  data-aos="fade-down" data-aos-duration="800" data-aos-offset="120">
              <div className="col-lg-12 col-md-12 col-sm-12 faq-service mb-30 mt-30">
                {/* <h3>Reporting and Cost Control</h3> */}
                <div className="text-content mb-30">
                  <p>
                    Contract recruitment is commonly used across various
                    industries and sectors, including information technology,
                    engineering, finance, healthcare, creative services, and
                    more.
                    <strong>Welcome to OneCloud Services,</strong> where we
                    redefine the realm of contract recruitment services. As a
                    reputable agency in this dynamic sector, we offer a truly
                    exceptional experience tailored to meet your
                    organization&#39;s unique needs. Allow us to present you
                    with our unparalleled approach to contract recruitment,
                    designed to empower your workforce with flexibility and
                    expertise.
                  </p>
                </div>
                <div className="panel-group service-faq faq" id="accordion">
                  
                  <div className="panel panel-default panel-primary">
                    <div className="panel-heading">
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapse-1"
                          aria-expanded="true"
                        >
                          Unleashing the Power of Contract Recruitment
                          <i className="fa fa-plus collape-plus" />
                        </a>
                      </h4>
                    </div>
                    <div id="collapse-1" className="panel-collapse collapse in">
                      <div className="panel-body">
                        <p>
                          At OneCloud Services, we understand that the modern
                          business landscape demands agility and adaptability.
                          Our contract recruitment services are specifically
                          designed to provide you with the talented
                          professionals you need, precisely when you need them.
                          Whether you require skilled individuals for short-term
                          projects, seasonal demands, or specialized
                          assignments, we have the solution.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default panel-primary">
                    <div className="panel-heading">
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapse-2"
                          aria-expanded="false"
                        >
                          Unrivaled Industry Knowledge
                          <i className="fa fa-plus collape-plus" />
                        </a>
                      </h4>
                    </div>
                    <div id="collapse-2" className="panel-collapse collapse">
                      <div className="panel-body">
                        <p>
                          Our agency stands out due to our deep industry
                          knowledge. Our team of recruitment experts possesses a
                          comprehensive understanding of diverse sectors and the
                          specific requirements associated with them. This
                          expertise enables us to swiftly identify and source
                          highly qualified candidates, ensuring a seamless match
                          between their skills and your project&#39;s demands.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default panel-primary">
                    <div className="panel-heading">
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapse-3"
                          aria-expanded="false"
                        >
                          A Vast Talent Pool at Your Fingertips
                          <i className="fa fa-plus collape-plus" />
                        </a>
                      </h4>
                    </div>
                    <div id="collapse-3" className="panel-collapse collapse">
                      <div className="panel-body">
                        <p>
                          Partnering with OneCloud Services grants you access to
                          an extensive talent pool brimming with exceptional
                          professionals. Our vast network spans various
                          industries and disciplines, ensuring that we can
                          quickly identify and connect you with top-class
                          candidates. With us, you&#39;ll have a competitive
                          advantage, accessing a rich resource of talent that
                          will drive your projects forward.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default panel-primary">
                    <div className="panel-heading">
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapse-4"
                          aria-expanded="false"
                        >
                          Uncompromising Candidate Screening
                          <i className="fa fa-plus collape-plus" />
                        </a>
                      </h4>
                    </div>
                    <div id="collapse-4" className="panel-collapse collapse">
                      <div className="panel-body">
                        <p>
                          We take pride in our rigorous candidate screening
                          process. We understand that your projects require
                          individuals with the right blend of skills,
                          experience, and expertise. Our meticulous approach
                          guarantees that only the most qualified candidates
                          make their way to your organization. By thoroughly
                          assessing qualifications and conducting in-depth
                          interviews, we ensure that every professional we
                          present possesses the necessary capabilities to excel
                          in their assigned roles.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default panel-primary">
                    <div className="panel-heading">
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapse-5"
                          aria-expanded="false"
                        >
                          Expedience and Efficiency
                          <i className="fa fa-plus collape-plus" />
                        </a>
                      </h4>
                    </div>
                    <div id="collapse-5" className="panel-collapse collapse">
                      <div className="panel-body">
                        <p>
                          At OneCloud Services, we recognize the significance of
                          prompt and efficient service in the contract
                          recruitment sector. Time is of the essence, and we
                          strive to minimize delays, ensuring that your projects
                          stay on track. By streamlining our recruitment
                          process, we expedite the identification, evaluation,
                          and placement of candidates, enabling seamless project
                          execution.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default panel-primary">
                    <div className="panel-heading">
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapse-6"
                          aria-expanded="false"
                        >
                          Customized Solutions for Your Success
                          <i className="fa fa-plus collape-plus" />
                        </a>
                      </h4>
                    </div>
                    <div id="collapse-6" className="panel-collapse collapse">
                      <div className="panel-body">
                        <p>
                          We understand that your organization is unique, with
                          its own goals, culture, and values. That&#39;s why we
                          take a personalized approach to every partnership. We
                          collaborate closely with you, gaining an in-depth
                          understanding of your requirements and project
                          objectives. This allows us to tailor our services to
                          align perfectly with your organization&#39;s needs,
                          ensuring the right fit between candidate and company.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default panel-primary">
                    <div className="panel-heading">
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapse-7"
                          aria-expanded="false"
                        >
                          Building Lasting Relationships
                          <i className="fa fa-plus collape-plus" />
                        </a>
                      </h4>
                    </div>
                    <div id="collapse-7" className="panel-collapse collapse">
                      <div className="panel-body">
                        <p>
                          At OneCloud Services, we believe in the power of
                          long-term relationships. We value not only our clients
                          but also the candidates we place in contract
                          positions. By fostering a supportive and nurturing
                          environment, we build strong connections with
                          professionals, encouraging their continued
                          collaboration with us. Our dedication to candidate
                          satisfaction ensures that we attract and retain top
                          talent for your projects.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default panel-primary">
                    <div className="panel-heading">
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapse-8"
                          aria-expanded="false"
                        >
                          A Stellar Track Record
                          <i className="fa fa-plus collape-plus" />
                        </a>
                      </h4>
                    </div>
                    <div id="collapse-8" className="panel-collapse collapse">
                      <div className="panel-body">
                        <p>
                          Our track record speaks volumes. OneCloud Services has
                          consistently delivered successful contract placements,
                          resulting in delighted clients and outstanding project
                          outcomes. Our reputation for excellence has made us
                          the trusted choice for companies seeking contract
                          recruitment services, and we take pride in our
                          contributions to our clients&#39; achievements.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="panel panel-default panel-primary">
                    <div className="panel-heading">
                      <h4 className="panel-title">
                        <a
                          className="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapse-9"
                          aria-expanded="false"
                        >
                          Choose OneCloud Services
                          <i className="fa fa-plus collape-plus" />
                        </a>
                      </h4>
                    </div>
                    <div id="collapse-9" className="panel-collapse collapse">
                      <div className="panel-body">
                        <p>
                          When it comes to contract recruitment services,
                          OneCloud Services stands head and shoulders above the
                          rest. We offer a truly exceptional and unique
                          experience, blending our industry knowledge, extensive
                          talent pool, customized solutions, and commitment to
                          excellence. Trust us to empower your workforce with
                          the flexibility and expertise required to thrive in
                          today&#39;s ever-evolving business landscape.
                        </p>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
