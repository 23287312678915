import React, { useState } from "react";
import * as job_services from "../utils/JobServices";
import { useNavigate } from "react-router-dom";

const ShortLinks = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [skillQuery, setSkillQuery] = useState([]);
  const [location, setLocation] = useState("");
  const [locationQuery, setLocationQuery] = useState([]);
  const [category, setCategory] = useState("");
  const [categoryQuery, setCategoryQuery] = useState([]);

  // Skill Search Start

  const handleInputChange = (value) => {
    setQuery(value);
    getSkillData(value);
  };

  const getSkillData = (value) => {
    job_services.jobSkillList().then((res) => {
      const results = res.data.filter((element) => {
        return (
          value &&
          element &&
          element.skillName &&
          element.skillName.toLowerCase().includes(value)
        );
      });
      setSkillQuery(results);
    });
  };

  const selectSkill = (skillName) => {
    setQuery(skillName);
    setSkillQuery([]);
  };

  // Skill Search End

  // Location Search Start

  const handleLocationChange = (value) => {
    setLocation(value);
    getLocationData(value);
  };

  const getLocationData = (value) => {
    job_services.jobLocationList().then((res) => {
      const results = res.data.filter((element) => {
        return (
          value &&
          element &&
          element.locationName &&
          element.locationName.toLowerCase().includes(value)
        );
      });
      setLocationQuery(results);
    });
  };

  const selectLocation = (locationName) => {
    setLocation(locationName);
    setLocationQuery([]);
  };
  // Location Search End

  // Category Search Start

  const handleCategoryChange = (value) => {
    setCategory(value);
    getCategoryData(value);
  };

  const getCategoryData = (value) => {
    job_services.jobCategoryList().then((res) => {
      const results = res.data.filter((element) => {
        return (
          value &&
          element &&
          element.categoryName &&
          element.categoryName.toLowerCase().includes(value)
        );
      });
      setCategoryQuery(results);
    });
  };

  const selectCategory = (categoryName) => {
    setCategory(categoryName);
    setCategoryQuery([]);
  };

  // Category Search End

  const handleSearch = (event) => {
    event.preventDefault();
    if (!query || !location || !category) {
      alert("Please Fill the filter first");
    } else {
      // let searchParam = {};
      // searchParam.query = query;
      // searchParam.location = location;
      // searchParam.category = category;
      // alert(JSON.stringify(searchParam));
      navigate("/JobList", {state:{ 'query': query, 'location': location, 'category': category }});
    }
  };
  return (
    <section>
      <div className="container searchJob">
        <div className="row ptb-15">
          <div className="img-content"></div>
          <div
            id="searchJob"
            className="form-content"
            style={{ border: "3px solid white", borderRadius: 3 }}
          >
            <form>
              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                <input
                  type="text"
                  className="form-control input-sm"
                  placeholder="Keywords-React/Java"
                  style={{ borderRadius: 2 }}
                  onChange={(e) => handleInputChange(e.target.value)}
                  value={query}
                />

                <div className="results-list">
                  {skillQuery.length > 0 &&
                    skillQuery.map((i, index) => (
                      <div
                        key={index}
                        className="search-result"
                        onClick={(e) => selectSkill(i.skillName)}
                      >
                        {i.skillName}
                      </div>
                    ))}
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 form-group">
                <input
                  type="text"
                  className="form-control input-sm"
                  id="location"
                  placeholder="Location"
                  style={{ borderRadius: 2 }}
                  onChange={(e) => handleLocationChange(e.target.value)}
                  value={location}
                />

                <div className="results-list">
                  {locationQuery.length > 0 &&
                    locationQuery.map((i, index) => (
                      <div
                        key={index}
                        className="search-result"
                        onClick={(e) => selectLocation(i.locationName)}
                      >
                        {i.locationName}
                      </div>
                    ))}
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 form-group">
                <input
                  type="text"
                  className="form-control input-sm"
                  id="categories"
                  placeholder="Categories"
                  style={{ borderRadius: 2 }}
                  onChange={(e) => handleCategoryChange(e.target.value)}
                  value={category}
                />
                <div className="results-list">
                  {categoryQuery.length > 0 &&
                    categoryQuery.map((i, index) => (
                      <div
                        key={index}
                        className="search-result"
                        onClick={(e) => selectCategory(i.categoryName)}
                      >
                        {i.categoryName}
                      </div>
                    ))}
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 form-group">
                <button
                  type="button"
                  className="btn btn-sm btn-block"
                  style={{
                    background: "#0f64db",
                    color: "#FFF",
                    fontSize: 14,
                  }}
                  onClick={(event) => handleSearch(event)}
                >
                  <i className="fa fa-search iconStyle"></i> Search Jobs
                </button>
              </div>
            </form>
            <div
              className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
              style={{ color: "#FFF", fontSize: 12 }}
            >
              <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                Trending Search
              </div>
              <div
                className="col-lg-2 col-md-2 col-sm-4 col-xs-4"
                style={{ fontSize: 12, fontWeight: "300" }}
              >
                PHP DEVELOPER
              </div>
              <div
                className="col-lg-2 col-md-2 col-sm-4 col-xs-4"
                style={{ fontSize: 12, fontWeight: "300" }}
              >
                FRONTEND
              </div>
              <div
                className="col-lg-2 col-md-2 col-sm-4 col-xs-4"
                style={{ fontSize: 12, fontWeight: "300" }}
              >
                LINUX
              </div>
              <div
                className="col-lg-2 col-md-2 col-sm-4 col-xs-4"
                style={{ fontSize: 12, fontWeight: "300" }}
              >
                IOS/ANDROID
              </div>
              <div
                className="col-lg-2 col-md-2 col-sm-4 col-xs-4"
                style={{ fontSize: 12, fontWeight: "300" }}
              >
                MARKETING
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// class ShortLinks extends Component {
//   render() {
//     return (
//       <>

//                 <SearchForm />

//       </>
//     );
//   }
// }

export default ShortLinks;
