import React, { Component } from "react";

class AboutUs extends Component {
  render() {
    return (
      <>
        {/* Intro Section */}
        <section
          data-aos="fade-up"
          data-aos-duration="800"
          className="inner-intro padding"
          style={{
            background: "url(assets/images/aboutus.jpg)",
            backgroundSize:'cover',
            left: 0,
            top: 0,
            width: "100%",
            height:'600px',
            zIndex:-1
          }}
        >
          <div
            className="container"
            data-aos="fade"
            data-aos-duration="100"
          >
            <div className="row title">
              <h1 style={{ color: "#CD1818" }}> About</h1>
            </div>
          </div>
        </section>
        {/* Intro Section */}

        {/* About Section */}
        <div
          id="about-section"
          className="padding pt-xs-60"
          data-aos="fade-down"
          data-aos-duration="800"
          data-aos-offset="100"
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="heading-box pb-30">
                  <h2>
                    <span>Company</span> Overview
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className="col-sm-6 pb-xs-30"
                data-aos="fade"
                data-aos-duration="800"
                data-aos-offset="100"
              >
                <div className="text-content text-justify">
                  <p>
                    <strong>
                      OneCloud services is a leading provider of recruitment
                    </strong>
                    services in the healthcare, management, and IT industries.
                    With years of experience and a team of expert recruiters,
                    OneCloud offers a comprehensive range of solutions designed
                    to meet the unique hiring needs of its clients. OneCloud, we
                    understand the challenges of finding the right talent to
                    fill critical roles in these industries. Our team is
                    committed to providing personalised services that help our
                    clients attract, hire, and retain the best candidates.
                    Whether it's a temporary staffing solution or a long-term
                    recruitment strategy, we have the expertise and resources to
                    deliver results.
                  </p>
                  <p
                    data-aos="fade-down"
                    data-aos-duration="800"
                    data-aos-offset="110"
                  >
                    Our approach is based on a deep understanding of the
                    industries we serve. We stay up to date with the latest
                    trends, technologies, and best practices to ensure that our
                    clients have access to the most qualified candidates. Our
                    team of recruiters works closely with clients to develop
                    tailored solutions that align with their unique goals and
                    requirements. At OneCloud, we believe in building lasting
                    relationships with our clients. We are committed to
                    providing exceptional customer service and maintaining open
                    lines of communication throughout the recruitment process.
                    Our goal is to become a trusted partner in our clients'
                    success by providing top-notch recruitment services that
                    help them achieve their business objectives.
                  </p>
                </div>
                <h4>In short, OneCloud is the go-to source for</h4>
                <ul
                  className="list"
                  data-aos="fade-down"
                  data-aos-duration="800"
                  data-aos-offset="110"
                >
                  <li>
                    <i className="ion-android-done-all text-color"> </i> Health
                    Care Services
                  </li>
                  <li>
                    <i className="ion-android-done-all text-color"> </i>{" "}
                    Contract Management Services
                  </li>
                  <li>
                    <i className="ion-android-done-all text-color"> </i> IT
                    Recruitment Services
                  </li>
                  <li>
                    <i className="ion-android-done-all text-color"> </i>Home
                    Care Services
                  </li>
                  <li>
                    <i className="ion-android-done-all text-color"> </i>Overseas
                    Recruitment
                  </li>
                  <li>
                    <i className="ion-android-done-all text-color"> </i>{" "}
                    Temporary Agency Staffing
                  </li>
                </ul>
              </div>
              <div
                className="col-sm-6"
                data-aos="fade"
                data-aos-duration="800"
                data-aos-offset="110"
              >
                <img
                  className="img-responsive img-rounded"
                  src="assets/images/vision.jpg"
                  alt="Temporary Staffing"
                />
              </div>
            </div>
          </div>

          <section style={{ marginBottom: 10 }}>
            {/* About Section */}
            <div id="about-section" className="pt-80 pt-xs-60">
              <div className="container">
                <div className="row">
                  <div
                    className="col-sm-6 col-md-8"
                    data-aos="fade"
                    data-aos-duration="800"
                    data-aos-offset="110"
                  >
                    <div className="block-title v-line">
                      <h2>
                        <span>Our</span> MISSION
                      </h2>
                      <p className="italic"> our size is our strength</p>
                    </div>
                    <div className="text-content">
                      <p>
                        Our mission is to deliver dependable staffing and
                        recruitment solutions to our clients by presenting them
                        with candidates who precisely match their
                        specifications.
                      </p>
                    </div>
                    <hr className="divider" />
                    <div className="post-content">
                      <div className="post-img">
                        <img
                          className="img-responsive"
                          src="assets/images/mission.jpg"
                          alt="Our Uniqueness"
                          style={{ height: 300, width: "100%" }}
                        />
                      </div>
                    </div>
                    <hr className="divider" />
                    <div
                      className="block-title v-line"
                      data-aos="fade-up"
                      data-aos-duration="800"
                      data-aos-offset="110"
                    >
                      <h2>
                        <span>Our</span> Uniqueness
                      </h2>
                      <p className="italic"> our size is our strength</p>
                    </div>
                    <div
                      className="text-content text-justify"
                      data-aos="fade-down"
                      data-aos-duration="800"
                      data-aos-offset="110"
                    >
                      <p>
                        We’re small enough to care but big enough to make an
                        impact. We're passionate about our work and have a deep
                        understanding of our sectors, with the agility to
                        respond quickly to client needs. We attribute our
                        success to our valued employees, and prioritize their
                        well-being, allowing them to provide excellent service.
                        Our actions and decisions are guided by teamwork,
                        respect, integrity, determination, understanding, and
                        fun. With top-of-the-line digital integration systems,
                        we offer the best recruitment service available.
                      </p>
                    </div>
                  </div>

                  <div
                    className="col-sm-6 col-md-4"
                    data-aos="fade"
                    data-aos-duration="800"
                    data-aos-offset="110"
                  >
                    <div className="dark-bg our-vision light-color padding-40">
                      <div className="block-title">
                        <h2>
                          <span>Our</span> VISION
                        </h2>
                        {/* <p className="italic"> Aenean suscipit eget mi act </p> */}
                      </div>
                      <p className="text-justify">
                        Our aspirations for the future are lofty and
                        substantial. We are determined to achieve significant
                        growth within the next few years, and to accomplish
                        this, we seek individuals who possess exceptional skills
                        and qualities such as experience, self-motivation,
                        enthusiasm, and inquisitiveness.
                      </p>
                      <p className="text-justify">
                        The recruitment industry has often been associated with
                        negative connotations, and we are committed to changing
                        this by transforming its image. This is a formidable
                        task, but one that inspires us to pursue excellence on a
                        daily basis. Our motto, "Partnering for Success:
                        Empowering Your Business with Top Talent," reflects our
                        dedication to providing exceptional talent to our
                        clients.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* About Section End*/}
          </section>
        </div>
        {/* About Section End*/}
      </>
    );
  }
}

export default AboutUs;
