import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export class PageError extends Component {
  render() {
    return (
      <>
        <div className="page-404 ptb-80 ptb-xs-60">
          <div className="container">
            {/*Footer Info */}
            <div className="row text-center">
              <div className="col-sm-6 col-sm-offset-3">
                <strong className="big-font">
                  4<i className="ion-android-happy" />4
                </strong>
                <h2>Page Not Found..</h2>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Repudiandae odit iste exerc ssumenda voluptas quidem sit
                  maiores odio velit voluptate.
                </p>
                <NavLink to={"/"} className="btn-text mt-15">
                  Back to home page
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PageError;
