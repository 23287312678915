import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import Logo from "../assets/MainCloud.png";

class HeaderMenu extends Component {
  render() {
    return (
      <>
        <header>
          <div className="top-part__block ptb-15">
            <div className="search__box-block">
              <div className="container">
                <input
                  type="text"
                  id="search"
                  className="input-sm form-full"
                  placeholder="Search Now"
                />
                <NavLink to="/" className="search__close-btn">
                  <i className="fa fa-times" aria-disabled="true" />
                </NavLink>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-sm-7">
                  <p>
                    Partnering for Success: Empowering Your Business with Top
                    Talent
                  </p>
                </div>
                <div className="col-sm-5">
                  <div className="social-link__block text-right">
                    <Link
                      to="/Login"
                      className="btn btn-sm btn-flat"
                      style={{ background: "#CD1818", color: "#FFF" }}
                    >
                      Login/Sign Up
                    </Link>
                    <Link
                      to="https://www.facebook.com/OneCloudServices"
                      target="_new"
                    >
                      <i className="fa fa-facebook" />
                    </Link>
                    <Link
                      to="https://www.instagram.com/weareonecloudservices/"
                      target="_new"
                    >
                      <i className="fa fa-instagram" />
                    </Link>
                    <Link
                      to="https://www.linkedin.com/company/onecloud-services/"
                      target="_new"
                    >
                      <i className="fa fa-linkedin" />
                    </Link>
                    <NavLink to="/" className="search__btn-option">
                      <i className="fa fa-search" />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="main_nav stricky-header__top">
            <nav className="navbar bootsnav">
              <div className="container">
                <div className="row">
                  <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
                    {/* Start Header Navigation */}
                    <div className="navbar-header">
                      <button
                        type="button"
                        className="navbar-toggle"
                        data-toggle="collapse"
                        data-target="#navbar-menu"
                      >
                        <i className="fa fa-bars" />
                      </button>

                      <div className="navbar-header">
                        <Link to="/" className="navbar-brand">
                          <img src={Logo} alt="Logo" />
                        </Link>
                      </div>
                    </div>
                    {/* End Header Navigation */}
                  </div>

                  <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                    {/* Collect the nav links, forms, and other content for toggling */}
                    <div className="collapse navbar-collapse" id="navbar-menu">
                      <ul className="nav navbar-nav mobile-menu">
                        <li>
                          <NavLink to="/">Home</NavLink>
                        </li>
                        <li>
                          <NavLink to="AboutUs">About us</NavLink>
                        </li>
                        <li>
                          <NavLink to="Service">services</NavLink>
                          <span className="submenu-button" />
                          <ul className="dropdown-menu">
                            <li>
                              <NavLink to="Contracting">Contracting</NavLink>
                            </li>
                            <li>
                              <NavLink to="Permanent">Permanent</NavLink>
                            </li>
                            <li>
                              <NavLink to="Temporary">
                                Temporary Agency Staffing
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="HomeCare">HomeCare</NavLink>
                            </li>
                            <li>
                              <NavLink to="Overseas">
                                Overseas Recruitment
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="Training">Training</NavLink>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <NavLink to="Industries">Industries</NavLink>
                          <span className="submenu-button" />
                          <ul className="dropdown-menu">
                            <li>
                              <NavLink to="HealthCare">HealthCare</NavLink>
                            </li>

                            <li>
                              <NavLink to="HomeCare">HomeCare</NavLink>
                            </li>
                            <li>
                              <NavLink to="Financial">Financial</NavLink>
                            </li>
                            <li>
                              <NavLink to="Banking">Banking</NavLink>
                            </li>
                            <li>
                              <NavLink to="CapitalMarket">
                                Capital Market
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="InformationTechnology">
                                Information Technology
                              </NavLink>
                            </li>
                          </ul>
                        </li>

                        <li>
                          <NavLink to="Contact">Contact</NavLink>
                        </li>

                        <li>
                          <NavLink to="/UploadCv">
                            <span
                              style={{
                                background: "#0F64DB",
                                color: "#FFF",
                                padding: 4,
                                borderRadius: 2,
                              }}
                            >
                              Upload CV
                            </span>
                          </NavLink>
                        </li>

                        <li>
                          <NavLink to="/Login">
                            <span
                              style={{
                                background: "#0F64DB",
                                color: "#FFF",
                                padding: 4,
                                borderRadius: 2,
                              }}
                            >
                             Post A Job
                            </span>
                          </NavLink>
                        </li>

                        <li>
                          <NavLink to="/JobSeeker">
                            <span
                              style={{
                                background: "#0F64DB",
                                color: "#FFF",
                                padding: 4,
                                borderRadius: 2,
                              }}
                            >
                              Find Talents
                            </span>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                    {/*navbar-collapse */}
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </header>
        {/*Section End Here*/}
      </>
    );
  }
}

export default HeaderMenu;
